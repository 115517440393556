import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Checkbox,
  Card,
  Layout,
  message
} from 'antd'
import { withCookies } from 'react-cookie'
import moment from 'moment'

import PicthisLogoWithBg from '../../img/picthis-logo-red-bg.png'
import { login } from '../../api/api'
import userManagement from '../../utils/UserManagement'

class Login extends Component {
  state = { username: null, password: null, remember: false, loading: false }

  handleInputChanged = (input, value) => {
    this.setState({ [input]: value })
  }

  handleLoginClicked = async () => {
    const { username, password, remember } = this.state

    if (username && password) {
      try {
        const {
          success,
          user,
          sid,
          valid_thru: validThru,
          error
        } = (await login(username, password, remember ? 1 : 0)).data

        if (success) {
          const { cookies } = this.props
          userManagement.setLoggedInUser(user)
          if (remember) {
            // user will angemeldet bleiben
            const validThruDate = moment(
              validThru,
              'YYYY-MM-DD HH:mm:ss'
            ).toDate()
            cookies.set('sid', sid, {
              path: '/',
              expires: validThruDate,
              httpOnly: false
            })
          } else {
            // login nur für diese sitzung gültig
            cookies.set('sid', sid, {
              path: '/',
              httpOnly: false
            })
          }
          /*
          if (onSuccess) {
            // parent component mitteilen, dass der login erfolgreich war
            onSuccess()
          }
          */
          window.location.reload()
        } else {
          message.error(error)
        }
      } catch (error) {
        console.error(error)
        message.error('Falscher Benutzername oder falsches Passwort.')
      }
    } else {
      message.error('Bitte alle Felder ausfüllen.')
    }
  }

  handleEnterPressed = (e) => {
    if (e.key === 'Enter') {
      this.handleLoginClicked()
    }
  }

  render() {
    const { username, password, remember, loading } = this.state

    return (
      <Layout style={loginStyles.layout}>
        <Layout.Content
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Row style={loginStyles.row}>
            <Col
              xs={{ span: 24, offset: 0 }}
              md={{ span: 8, offset: 8 }}
              xl={{ span: 6, offset: 9 }}
              style={loginStyles.col}
            >
              <img
                src={PicthisLogoWithBg}
                style={loginStyles.logoImg}
                title="Logo"
                alt="Logo"
              />
              <Card loading={loading} style={{ width: '100%' }}>
                <Form onSubmit={null}>
                  <Form.Item style={loginStyles.formItem}>
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="Alias / E-Mail"
                      value={username || ''}
                      onChange={(e) =>
                        this.handleInputChanged('username', e.target.value)
                      }
                      onKeyPress={this.handleEnterPressed}
                      size="large"
                      style={{ padding: '12px', maxHeight: 'none' }}
                    />
                  </Form.Item>
                  <Form.Item style={loginStyles.formItem}>
                    <Input
                      prefix={<LockOutlined />}
                      type="password"
                      placeholder="Kennwort"
                      value={password || ''}
                      onChange={(e) =>
                        this.handleInputChanged('password', e.target.value)
                      }
                      onKeyPress={this.handleEnterPressed}
                      size="large"
                      style={{ padding: '12px', maxHeight: 'none' }}
                    />
                  </Form.Item>
                  <Form.Item style={loginStyles.formItem}>
                    <Checkbox
                      checked={remember}
                      onChange={(e) =>
                        this.handleInputChanged('remember', e.target.checked)
                      }
                      onKeyPress={this.handleEnterPressed}
                    >
                      5 Tage lang angemeldet bleiben
                    </Checkbox>
                  </Form.Item>
                </Form>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.handleLoginClicked}
                  size="large"
                >
                  Anmelden
                </Button>
              </Card>
            </Col>
          </Row>
        </Layout.Content>
        <Layout.Footer style={loginStyles.footer}>
          &copy; Stefan Riedler 2021
        </Layout.Footer>
      </Layout>
    )
  }
}

Login.propTypes = {
  cookies: PropTypes.shape({
    set: PropTypes.func
  })
}

const loginStyles = {
  layout: {
    height: '100vh',
    color: 'white',
    textAlign: 'center'
  },
  row: {
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  col: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  formItem: {
    marginBottom: '5px'
  },
  logoImg: {
    width: '140px',
    height: 'auto',
    margin: '24px 0',
    borderRadius: '12px'
  },
  footer: {
    backgroundColor: '#333333',
    color: '#aaaaaa'
  }
}

export default withCookies(Login)
