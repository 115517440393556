import React, { Component } from 'react'
import { Statistic, Spin, Alert } from 'antd'
import { filter } from 'rxjs/operators'
import { getCountForEvent } from '../../api/participant'
import {
  NotificationSubject,
  NotificationNames
} from '../../utils/NotificationManagement'
import { getSelectedAtpEvent } from '../../utils/AtpEventManagement'

class EventTeilnehmerAnzahl extends Component {
  state = { loading: false, participantCount: null }

  constructor(props) {
    super(props)
    this.unsubscribeFromNotifications = NotificationSubject.pipe(
      filter(
        (f) =>
          f.notificationName ===
          NotificationNames.userSelectedAtpEventNotification
      )
    ).subscribe(() => {
      this.loadParticipantCount()
    })
  }

  loadParticipantCount = async () => {
    const event = getSelectedAtpEvent()
    if (event) {
      this.setState({ loading: true })
      const { success, participantCount } = (
        await getCountForEvent(event.id)
      ).data
      if (success) {
        this.setState({ loading: false, participantCount })
      } else {
        this.setState({ loading: false })
      }
    }
  }

  componentDidMount = () => {
    this.loadParticipantCount()
  }

  componentWillUnmount = () => {
    this.unsubscribeFromNotifications.unsubscribe()
  }

  render() {
    const { loading, participantCount } = this.state
    const event = getSelectedAtpEvent()

    return (
      <Spin spinning={loading}>
        {!event && (
          <Alert
            type="warning"
            showIcon
            message="Noch kein Event ausgewählt."
          />
        )}
        {event && (
          <Statistic
            value={participantCount}
            title="Anzahl Teilnehmer"
            suffix={<small>Teilnehmer:innen</small>}
          />
        )}
      </Spin>
    )
  }
}

export default EventTeilnehmerAnzahl
