/* export const FormatMoney = (value, precision) =>
  (Number(value) / 100).toFixed(precision).replace('.', ',')
*/

export const FormatMoney = (
  value,
  precision,
  thousandSeperator = '.',
  decimalSeperator = ','
) => {
  let value2 = value
  const s = value2 < 0 ? '-' : ''
  const i = String(
    parseInt(
      (value2 = Math.abs(Number(value) / 100 || 0).toFixed(precision)),
      10
    )
  )
  let j = i.length
  j = j > 3 ? j % 3 : 0

  return `${s}${j ? `${i.substr(0, j)}${thousandSeperator}` : ''}${i
    .substr(j)
    .replace(/(\d{3})(?=\d)/g, `$1${thousandSeperator}`)}${
    precision
      ? `${decimalSeperator}${Math.abs(value2 - i)
          .toFixed(precision)
          .slice(2)}`
      : ''
  }`
}

export const FormatDuration = (value, asObject) => {
  const h = Math.floor(Math.abs(Number(value)) / 60)
  const min = Math.floor(Math.abs(Number(value)) % 60)

  if (asObject === true) {
    if (Number(value) < 0) {
      return { hours: h * -1, minutes: min * -1 }
    }
    return { hours: h, minutes: min }
  }

  let durationString = ''
  if (h < 10) {
    durationString += '0'
  }
  durationString += `${h}h`

  if (min < 10) {
    durationString += '0'
  }

  durationString += `${min}m`

  return durationString
}

export default { FormatMoney, FormatDuration }
