import PropTypes from 'prop-types'
import React, { Component } from 'react'

class EinzelnerTeilnehmer extends Component {
  state = {}

  render() {
    return <b>Teilnehmer: {this.props.id}</b>
  }
}

EinzelnerTeilnehmer.propTypes = {
  id: PropTypes.any
}

export default EinzelnerTeilnehmer
