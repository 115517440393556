import axios from 'axios'
import qs from 'qs'

export const apiGet = (object, endpoint, params) =>
  axios.get('/api/api.php', {
    params: { ...params, object, endpoint },
    timeout: 10 * 60 * 1000
  })

export const apiPost = (object, endpoint, params) =>
  axios.post('/api/api.php', qs.stringify({ object, endpoint, ...params }), {
    timeout: 10 * 60 * 1000
  })

export const apiPostFormData = (object, endpoint, formData) => {
  formData.append('object', object)
  formData.append('endpoint', endpoint)

  return axios.post('/api/api.php', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export const apiPostWithFiles = (object, endpoint, params) => {
  const formData = new FormData()
  formData.append('object', object)
  formData.append('endpoint', endpoint)

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      if (Array.isArray(params[key])) {
        if (key === 'files') {
          params[key].forEach((p) => {
            formData.append(`${key}[]`, p)
          })
        } else {
          formData.append(key, JSON.stringify(params[key]))
        }
      } else {
        formData.append(key, params[key])
      }
    }
  })

  return axios.post('/api/api.php', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const apiUpload = (params) => {
  const formData = new FormData()

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      formData.append(key, params[key])
    }
  })

  return axios.post('/api/upload.php', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export const login = (username, password, remember) =>
  axios.post(
    '/api/api.php?action=login',
    qs.stringify({ username, password, remember })
  )

export const logout = () => axios.get('/api/api.php?action=logout')

export const onlineUsers = () => axios.get('/api/online_users.php')

export const checkIfUserIsLoggedIn = () =>
  axios.get('/api/api.php', { params: { action: 'checkIfUserIsLoggedIn' } })

export const getCurrentVersion = () =>
  axios.get('/api/api.php', { params: { action: 'currentVersion' } })

export const customApiGetRequest = (url, params) => axios.get(url, { params })

export const customApiPostRequest = (url, params) =>
  axios.post(url, qs.stringify(params))

export const customApiPostWithFilesRequest = (url, params, files) => {
  const formData = new FormData()

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      if (Array.isArray(params[key])) {
        formData.append(key, JSON.stringify(params[key]))
      } else {
        formData.append(key, params[key])
      }
    }
  })

  files.forEach((file) => {
    formData.append('files[]', file)
  })

  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export default { apiGet, apiPost }
