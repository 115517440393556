import React, { Component } from 'react'
import {
  Alert,
  Button,
  message,
  PageHeader,
  Popconfirm,
  Row,
  Switch,
  Table
} from 'antd'
import moment from 'moment'
import {
  getAllEvents,
  toggleShowOnWebsite as toggleEvent,
  toggleReadyForSelling as toggleReady,
  queuePhotosReadyMailsForSubevent,
  queueReminderMailsForSubevent
} from '../../api/event'
import { EditOutlined, MailOutlined, PlusCircleFilled } from '@ant-design/icons'
import NeuesSubEventModal from './NeuesSubEventModal'
import NeuesEventModal from './NeuesEventModal'
import SubeventBewerbeDrawer from './SubeventBewerbeDrawer'
import UpdateEventModal from './UpdateEventModal'

class Events extends Component {
  state = {
    loading: false,
    error: null,
    events: [],
    selectedEvent: null,
    searchText: null,
    showCreateNewEventModal: false,
    showCreateNewSubeventModal: false,
    showEditBewerbeDrawer: false,
    createNewBewerb: false,
    editEvent: false
  }

  loadEvents = async () => {
    this.setState({ loading: true })

    const { success, error, events } = (await getAllEvents()).data

    if (success) {
      this.setState({ loading: false, events })
    } else {
      this.setState({ loading: false, error })
    }
  }

  toggleShowOnWebsite = async (eventId, show) => {
    this.setState({ loading: true, error: null })
    const {
      success,
      error,
      message: m
    } = (await toggleEvent(eventId, show)).data
    if (success) {
      this.setState({ loading: false }, this.loadEvents)
      message.success(m)
    } else {
      this.setState({ loading: false })
      message.error(error)
    }
  }

  toggleReadyForSelling = async (eventId, ready) => {
    this.setState({ loading: true, error: null })
    const {
      success,
      error,
      message: m
    } = (await toggleReady(eventId, ready)).data
    if (success) {
      this.setState({ loading: false }, this.loadEvents)
      message.success(m)
    } else {
      this.setState({ loading: false })
      message.error(error)
    }
  }

  handleSendPhotosReadyMails = async (eventId) => {
    this.setState({ loading: true, error: null })
    const {
      success,
      error,
      message: m
    } = (await queuePhotosReadyMailsForSubevent(eventId)).data

    this.setState({ loading: false })

    if (success) {
      message.success(m)
    } else {
      message.error(error)
    }
  }

  handleSendReminderMails = async (eventId) => {
    this.setState({ loading: true, error: null })
    const {
      success,
      error,
      message: m
    } = (await queueReminderMailsForSubevent(eventId)).data

    this.setState({ loading: false })

    if (success) {
      message.success(m)
    } else {
      message.error(error)
    }
  }

  componentDidMount = () => {
    this.loadEvents()
  }

  render() {
    const {
      loading,
      error,
      events,
      showCreateNewEventModal,
      showCreateNewSubeventModal,
      showEditBewerbeDrawer,
      selectedEvent,
      editEvent
    } = this.state

    return (
      <>
        <PageHeader
          title="Events"
          extra={
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => {
                this.setState({ showCreateNewEventModal: true })
              }}
            >
              Neues Event
            </Button>
          }
        >
          {error && (
            <Alert
              type="error"
              showIcon
              message={error}
              style={{ marginBottom: '24px' }}
            />
          )}
          <Table
            dataSource={events}
            columns={[
              { title: 'Event', key: 'name', dataIndex: 'event_name' },
              {
                title: 'Datum',
                key: 'date',
                dataIndex: 'event_start_date',
                render: (date) => moment(date).format('L'),
                defaultSortOrder: 'descend',
                sorter: (d1, d2) =>
                  moment(d1.event_start_date).unix() -
                  moment(d2.event_start_date).unix()
              },
              {
                title: 'Sub-Events',
                key: 'sub-events',
                render: (event) =>
                  event.subEvents ? event.subEvents.length : 0
              },
              {
                title: 'Auf Webseite anzeigen?',
                key: 'showOnWebsite',
                render: (event) => (
                  <Switch
                    loading={loading}
                    checked={Number(event.show_on_website) === 1}
                    onChange={() => {
                      this.toggleShowOnWebsite(
                        event.id,
                        Number(event.show_on_website) !== 1
                      )
                    }}
                  />
                )
              },
              {
                title: '',
                key: 'options',
                render: (event) => (
                  <>
                    <Button
                      size="small"
                      type="link"
                      icon={<PlusCircleFilled />}
                      onClick={() => {
                        this.setState({
                          showCreateNewSubeventModal: true,
                          selectedEvent: event
                        })
                      }}
                    >
                      Neues Sub-Event
                    </Button>
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => {
                        this.setState({ selectedEvent: event, editEvent: true })
                      }}
                    >
                      bearbeiten
                    </Button>
                  </>
                )
              }
            ]}
            expandable={{
              expandedRowRender: (event) => (
                <div style={{ marginBottom: '24px', paddingLeft: '48px' }}>
                  <Table
                    dataSource={event.subEvents}
                    columns={[
                      { title: 'Name', dataIndex: 'event_name' },
                      {
                        title: 'Datum',
                        dataIndex: 'event_start_date',
                        render: (date) => moment(date).format('L')
                      },
                      {
                        title: 'Auf Webseite anzeigen?',
                        key: 'showOnWebsite',
                        render: (event) => (
                          <Switch
                            size="small"
                            loading={loading}
                            checked={Number(event.show_on_website) === 1}
                            onChange={() => {
                              this.toggleShowOnWebsite(
                                event.id,
                                Number(event.show_on_website) !== 1
                              )
                            }}
                          />
                        )
                      },
                      {
                        title: 'Bereit für Verkauf?',
                        key: 'readyForSelling',
                        render: (event) => (
                          <Switch
                            size="small"
                            loading={loading}
                            checked={Number(event.ready_for_selling) === 1}
                            onChange={() => {
                              this.toggleReadyForSelling(
                                event.id,
                                Number(event.ready_for_selling) !== 1
                              )
                            }}
                          />
                        )
                      },
                      {
                        title: '',
                        key: 'options',
                        render: (subevent) => (
                          <>
                            {' '}
                            <Button
                              type="link"
                              onClick={() => {
                                this.setState({
                                  selectedEvent: subevent,
                                  showEditBewerbeDrawer: true
                                })
                              }}
                            >
                              Bewerbe bearbeiten
                            </Button>
                            <Popconfirm
                              title="Wirklich versenden?"
                              okText="Jetzt versenden"
                              onConfirm={() => {
                                this.handleSendPhotosReadyMails(subevent.id)
                              }}
                            >
                              <Button type="link" icon={<MailOutlined />}>
                                &quot;Fotos bereit&quot; Mail versenden
                              </Button>
                            </Popconfirm>
                            <Popconfirm
                              title="Wirklich versenden?"
                              okText="Jetzt versenden"
                              onConfirm={() => {
                                this.handleSendReminderMails(subevent.id)
                              }}
                            >
                              <Button type="link" icon={<MailOutlined />}>
                                &quot;Reminder&quot; versenden
                              </Button>
                            </Popconfirm>
                          </>
                        )
                      }
                    ]}
                    rowKey="id"
                    pagination={false}
                    size="small"
                  />
                  <Row
                    align="middle"
                    justify="start"
                    style={{ margin: '6px 0' }}
                  >
                    <Button
                      size="small"
                      type="primary"
                      icon={<PlusCircleFilled />}
                      onClick={() => {
                        this.setState({
                          showCreateNewSubeventModal: true,
                          selectedEvent: event
                        })
                      }}
                    >
                      Neues Sub-Event
                    </Button>
                  </Row>
                </div>
              ),
              rowExpandable: (event) =>
                event.subEvents && event.subEvents.length > 0
            }}
            rowKey="id"
            loading={loading}
          />
        </PageHeader>

        <NeuesEventModal
          visible={showCreateNewEventModal}
          onClose={() => {
            this.setState({ showCreateNewEventModal: false })
          }}
          onSuccess={(msg) => {
            message.success(msg)
            this.setState({ showCreateNewEventModal: false }, this.loadEvents)
          }}
        />

        <UpdateEventModal
          visible={selectedEvent && editEvent}
          event={selectedEvent}
          onClose={() => {
            this.setState({ selectedEvent: null, editEvent: false })
          }}
          onSuccess={(msg) => {
            message.success(msg)
            this.setState(
              { selectedEvent: null, editEvent: false },
              this.loadEvents
            )
          }}
        />

        <NeuesSubEventModal
          visible={showCreateNewSubeventModal}
          event={selectedEvent}
          onClose={() => {
            this.setState({ showCreateNewSubeventModal: false })
          }}
          onSuccess={(msg) => {
            message.success(msg)
            this.setState(
              { showCreateNewSubeventModal: false },
              this.loadEvents
            )
          }}
        />

        <SubeventBewerbeDrawer
          visible={showEditBewerbeDrawer}
          onClose={() => {
            this.setState({ showEditBewerbeDrawer: false })
          }}
          subevent={selectedEvent}
        />
      </>
    )
  }
}

export default Events
