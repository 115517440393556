import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert, DatePicker, Form, Input, Modal, Switch } from 'antd'
import moment from 'moment'
import { updateEvent } from '../../api/event'

class UpdateEventModal extends Component {
  state = {
    loading: false,
    error: null,
    eventName: null,
    eventDescription: null,
    eventStartDate: null,
    eventEndDate: null,
    urlKeywords: null,
    showOnWebsite: false,
    eventLocation: null,
    eventCountry: 'AT',
    eventWebsite: null,
    eventLogo: null,
    eventCoverImage: null,
    fotorahmenHoch: null,
    fotorahmenQuer: null
  }

  coverPhotoInputRef = React.createRef()
  eventLogoInputRef = React.createRef()
  fotorahmenHochInputRef = React.createRef()
  fotorahmenQuerInputRef = React.createRef()

  resetState = () => {
    this.setState(
      {
        loading: false,
        error: null,
        eventName: null,
        eventDescription: null,
        eventStartDate: null,
        eventEndDate: null,
        urlKeywords: null,
        showOnWebsite: false,
        eventLocation: null,
        eventCountry: 'AT',
        eventWebsite: null,
        eventLogo: null,
        eventCoverImage: null,
        fotorahmenHoch: null,
        fotorahmenQuer: null
      },
      () => {
        this.coverPhotoInputRef.current.value = null
        this.eventLogoInputRef.current.value = null
        this.fotorahmenHochInputRef.current.value = null
        this.fotorahmenQuerInputRef.current.value = null
      }
    )
  }

  handleSaveEvent = async () => {
    this.setState({ loading: true, error: null })
    const {
      eventName,
      eventDescription,
      eventStartDate,
      eventEndDate,
      urlKeywords,
      showOnWebsite,
      eventLocation,
      eventCountry,
      eventWebsite,
      eventLogo,
      eventCoverImage,
      fotorahmenHoch,
      fotorahmenQuer
    } = this.state

    const { event } = this.props

    const {
      success,
      error,
      message: m
    } = (
      await updateEvent(
        event.id,
        eventName,
        eventStartDate.format('YYYY-MM-DD'),
        eventEndDate.format('YYYY-MM-DD'),
        eventDescription,
        urlKeywords,
        eventLocation,
        eventCountry,
        eventWebsite,
        showOnWebsite,
        eventCoverImage,
        eventLogo,
        fotorahmenHoch,
        fotorahmenQuer
      )
    ).data

    if (success) {
      const { onSuccess } = this.props
      this.setState({ loading: false }, () => {
        this.resetState()
        onSuccess(m)
      })
    } else {
      this.setState({ loading: false, error })
    }
  }

  componentDidMount = () => {
    const { event } = this.props
    if (event) {
      this.setState({
        eventName: event.event_name,
        eventDescription: event.event_description,
        eventStartDate: moment(event.event_start_date),
        eventEndDate: moment(event.event_end_date),
        urlKeywords: event.url_keywords,
        showOnWebsite: Number(event.show_on_website) === 1,
        eventLocation: event.event_location,
        eventCountry: event.event_country,
        eventWebsite: event.event_website
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { event } = this.props
    const { event: prevEvent } = prevProps

    if (event && event !== prevEvent) {
      this.setState({
        eventName: event.event_name,
        eventDescription: event.event_description,
        eventStartDate: moment(event.event_start_date),
        eventEndDate: moment(event.event_end_date),
        urlKeywords: event.url_keywords,
        showOnWebsite: Number(event.show_on_website) === 1,
        eventLocation: event.event_location,
        eventCountry: event.event_country,
        eventWebsite: event.event_website
      })
    }
  }

  render() {
    const {
      loading,
      error,
      eventName,
      eventDescription,
      eventStartDate,
      eventEndDate,
      urlKeywords,
      showOnWebsite,
      eventLocation,
      eventCountry,
      eventWebsite
    } = this.state

    const { visible, onClose } = this.props
    return (
      <Modal
        title="Event bearbeiten"
        visible={visible}
        onCancel={onClose}
        width={800}
        centered
        maskClosable={false}
        okText="Änderungen speichern"
        onOk={this.handleSaveEvent}
        confirmLoading={loading}
      >
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '24px' }}
          />
        )}
        <Form wrapperCol={{ span: 15 }} labelCol={{ span: 9 }} size="large">
          <Form.Item label="Event Name" required>
            <Input
              placeholder="Event Name"
              value={eventName}
              onChange={(e) => {
                this.setState({ eventName: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item label="Kurze Beschreibung" required>
            <Input.TextArea
              placeholder="Kurze Beschreibung zum Event"
              value={eventDescription}
              onChange={(e) => {
                this.setState({ eventDescription: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item label="Start-Datum" required>
            <DatePicker
              value={eventStartDate}
              onChange={(d) => {
                this.setState({ eventStartDate: d })
              }}
              format="ddd, DD.MM.YYYY"
            />
          </Form.Item>
          <Form.Item label="End-Datum" required>
            <DatePicker
              value={eventEndDate}
              onChange={(d) => {
                this.setState({ eventEndDate: d })
              }}
              format="ddd, DD.MM.YYYY"
            />
          </Form.Item>
          <Form.Item
            label="URL Keywords"
            required
            help={
              <>
                <b>ACHTUNG:</b> Kann danach nicht mehr geändert werden.
                Vorschau: picthis.one/{urlKeywords || ''}
              </>
            }
          >
            <Input
              placeholder="z.B. atp-2022"
              value={urlKeywords}
              onChange={(e) => {
                this.setState({ urlKeywords: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item label="Event Ort" required>
            <Input
              placeholder="z.B. Podersdorf am See, Burgenland"
              value={eventLocation}
              onChange={(e) => {
                this.setState({ eventLocation: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item label="Event Land" required>
            <Input
              placeholder="Länderkürzel, z.B. AT"
              value={eventCountry}
              onChange={(e) => {
                this.setState({ eventCountry: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item label="Offizielle Webseite" required>
            <Input
              placeholder="Die Webseite des Veranstalters"
              value={eventWebsite}
              onChange={(e) => {
                this.setState({ eventWebsite: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item
            label="Cover Photo"
            help="Wird groß auf picthis.one oben angezeigt"
          >
            <input
              type="file"
              multiple={false}
              onChange={(e) => {
                console.log('e', e)
                this.setState({ eventCoverImage: e.target.files[0] })
              }}
              ref={this.coverPhotoInputRef}
            />
          </Form.Item>
          <Form.Item label="Event Logo">
            <input
              type="file"
              multiple={false}
              onChange={(e) => {
                this.setState({ eventLogo: e.target.files[0] })
              }}
              ref={this.eventLogoInputRef}
            />
          </Form.Item>
          <Form.Item label="Auf Webseite anzeigen?">
            <Switch
              checked={showOnWebsite}
              onChange={(checked) => {
                this.setState({ showOnWebsite: checked })
              }}
            />
          </Form.Item>
          <Form.Item label="Fotorahmen hoch">
            <input
              type="file"
              multiple={false}
              onChange={(e) => {
                this.setState({ fotorahmenHoch: e.target.files[0] })
              }}
              ref={this.fotorahmenHochInputRef}
            />
          </Form.Item>
          <Form.Item label="Fotorahmen quer">
            <input
              type="file"
              multiple={false}
              onChange={(e) => {
                this.setState({ fotorahmenQuer: e.target.files[0] })
              }}
              ref={this.fotorahmenQuerInputRef}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

UpdateEventModal.propTypes = {
  event: PropTypes.shape({
    event_country: PropTypes.any,
    event_description: PropTypes.any,
    event_end_date: PropTypes.any,
    event_location: PropTypes.any,
    event_name: PropTypes.any,
    event_start_date: PropTypes.any,
    event_website: PropTypes.any,
    id: PropTypes.any,
    show_on_website: PropTypes.any,
    url_keywords: PropTypes.any
  }),
  onClose: PropTypes.any,
  onSuccess: PropTypes.func,
  visible: PropTypes.any
}

export default UpdateEventModal
