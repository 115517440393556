import PropTypes from 'prop-types'
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  UploadOutlined
} from '@ant-design/icons'
import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  message,
  Popconfirm,
  Popover,
  Upload
} from 'antd'
import React, { Component } from 'react'
import {
  createSubeventBewerb,
  deleteSubeventBewerb,
  updateSubeventBewerb
} from '../../api/event'
import {
  updateListForSubeventBewerb,
  updateSplitTimesForSubeventBewerb
} from '../../api/participant'
import FotorahmenEinstellungenModal from './FotorahmenEinstellungenModal'

class SubeventBewerbCard extends Component {
  state = {
    loading: false,
    error: null,
    bewerbName: null,
    fotorahmenGesamtHoch: null,
    fotorahmenGesamtQuer: null,
    fotorahmenSplitHoch: null,
    fotorahmenSplitQuer: null,
    participantListFile: null,
    splittimesFile: null,
    fotorahmenBearbeitenModalVisible: false
  }

  handleSaveBewerb = async () => {
    const { bewerb, subevent, mode } = this.props

    if (mode && mode === 'create') {
      const {
        bewerbName,
        fotorahmenGesamtHoch,
        fotorahmenGesamtQuer,
        fotorahmenSplitHoch,
        fotorahmenSplitQuer
      } = this.state
      this.setState({ loading: true, error: null })
      const {
        success,
        error,
        message: m
      } = (
        await createSubeventBewerb(
          subevent.id,
          bewerbName,
          fotorahmenGesamtHoch,
          fotorahmenGesamtQuer,
          fotorahmenSplitHoch,
          fotorahmenSplitQuer
        )
      ).data

      if (success) {
        const { onSuccess } = this.props
        this.setState({ loading: false }, () => {
          onSuccess(m)
        })
      } else {
        this.setState({ loading: false, error })
      }
    } else if (mode && mode === 'edit') {
      const {
        bewerbName,
        fotorahmenGesamtHoch,
        fotorahmenGesamtQuer,
        fotorahmenSplitHoch,
        fotorahmenSplitQuer
      } = this.state
      this.setState({ loading: true, error: null })
      const {
        success,
        error,
        message: m
      } = (
        await updateSubeventBewerb(
          bewerb.id,
          bewerbName,
          fotorahmenGesamtHoch,
          fotorahmenGesamtQuer,
          fotorahmenSplitHoch,
          fotorahmenSplitQuer
        )
      ).data

      if (success) {
        const { onSuccess } = this.props
        this.setState({ loading: false }, () => {
          onSuccess(m)
        })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  handleDeleteBewerb = async () => {
    const { bewerb } = this.props

    if (bewerb) {
      this.setState({ loading: true, error: null })
      const {
        success,
        error,
        message: m
      } = (await deleteSubeventBewerb(bewerb.id)).data

      if (success) {
        const { onSuccess } = this.props
        this.setState({ loading: false }, () => {
          onSuccess(m)
        })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  handleUploadParticipantList = async () => {
    this.setState({ loading: false, error: null })
    const { bewerb } = this.props
    const { participantListFile } = this.state
    const {
      success,
      error,
      message: m
    } = (await updateListForSubeventBewerb(bewerb.id, participantListFile)).data

    if (success) {
      this.setState({ loading: false, participantListFile: null })
      message.success(m)
    } else {
      this.setState({ loading: false, error })
    }
  }

  handleUploadSplitTimes = async () => {
    this.setState({ loading: false, error: null })
    const { bewerb } = this.props
    const { splittimesFile } = this.state
    const {
      success,
      error,
      message: m
    } = (await updateSplitTimesForSubeventBewerb(bewerb.id, splittimesFile))
      .data

    if (success) {
      this.setState({ loading: false, splittimesFile: null })
      message.success(m)
    } else {
      this.setState({ loading: false, error })
    }
  }

  componentDidMount = () => {
    const { bewerb } = this.props

    if (bewerb) {
      this.setState({ bewerbName: bewerb.bewerb_name })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { bewerb } = this.props
    const { bewerb: prevBewerb } = prevProps

    if (bewerb && bewerb !== prevBewerb) {
      this.setState({ bewerbName: bewerb.bewerb_name })
    }
  }

  render() {
    const {
      loading,
      error,
      bewerbName,
      participantListFile,
      splittimesFile,
      fotorahmenBearbeitenModalVisible
    } = this.state
    const { bewerb, bewerbe, mode } = this.props
    return (
      <Card
        title={bewerb ? bewerb.bewerb_name : 'Bewerb eintragen'}
        extra={
          <>
            <Button
              type="link"
              onClick={this.handleSaveBewerb}
              icon={<SaveOutlined />}
              loading={loading}
            >
              Speichern
            </Button>
            {mode && mode === 'edit' && (
              <Popconfirm
                title="Löschen?"
                onConfirm={() => {
                  this.handleDeleteBewerb(bewerb.id)
                }}
              >
                <Button
                  type="link"
                  danger
                  icon={<DeleteOutlined />}
                  loading={loading}
                >
                  Löschen
                </Button>
              </Popconfirm>
            )}
          </>
        }
        style={{ margin: '12px 0' }}
        size="small"
        headStyle={{ backgroundColor: '#eeeeee' }}
      >
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '24px' }}
          />
        )}
        <Form>
          <Form.Item label="Bewerb Name">
            <Input
              value={bewerbName}
              onChange={(e) => {
                this.setState({ bewerbName: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item label="Fotorahmen Gesamtzeit hoch">
            <Input
              type="file"
              multiple={false}
              onChange={(e) => {
                this.setState({ fotorahmenGesamtHoch: e.target.files[0] })
              }}
            />
          </Form.Item>

          <Form.Item label="Fotorahmen Gesamtzeit quer">
            <Input
              type="file"
              multiple={false}
              onChange={(e) => {
                this.setState({ fotorahmenGesamtQuer: e.target.files[0] })
              }}
            />
          </Form.Item>
          <Form.Item label="Fotorahmen Splitzeit hoch">
            <Input
              type="file"
              multiple={false}
              onChange={(e) => {
                this.setState({ fotorahmenSplitHoch: e.target.files[0] })
              }}
            />
          </Form.Item>
          <Form.Item label="Fotorahmen Splitzeit quer">
            <Input
              type="file"
              multiple={false}
              onChange={(e) => {
                this.setState({ fotorahmenSplitQuer: e.target.files[0] })
              }}
            />
          </Form.Item>
          {mode && mode === 'edit' && (
            <Button
              type="primary"
              size="small"
              icon={<EditOutlined />}
              onClick={() => {
                this.setState({ fotorahmenBearbeitenModalVisible: true })
              }}
            >
              Fotorahmen bearbeiten
            </Button>
          )}
          {mode && mode === 'edit' && (
            <Form.Item label="Teilnehmerliste hochladen">
              <Popover
                title="Spalten für CSV"
                content={
                  <>
                    Es werden die Spalten <i>Startnr, Vorname, Nachname</i> und
                    optional <i>E-Mail</i> benötigt.
                  </>
                }
              >
                <QuestionCircleOutlined />
              </Popover>
              <Upload
                beforeUpload={(file) => {
                  this.setState({ participantListFile: file })
                  return false
                }}
                onRemove={() => {
                  this.setState({ participantListFile: null })
                }}
                fileList={participantListFile ? [participantListFile] : null}
                multiple={false}
                accept=".csv"
              >
                <Button size="small">Auswählen</Button>
              </Upload>
              <Button
                type="primary"
                icon={<UploadOutlined />}
                size="small"
                disabled={!participantListFile}
                onClick={this.handleUploadParticipantList}
                loading={loading}
              >
                Hochladen
              </Button>
            </Form.Item>
          )}
          {mode && mode === 'edit' && (
            <Form.Item label="Splitzeiten hochladen">
              <Popover
                title="Spalten für CSV"
                content={
                  <>
                    Es werden die Spalten{' '}
                    <i>
                      Startnr, Startzeit, SwimSplit, BikeSplit, RunSplit, Gesamt
                    </i>{' '}
                    benötigt.
                  </>
                }
              >
                <QuestionCircleOutlined />
              </Popover>
              <Upload
                beforeUpload={(file) => {
                  this.setState({ splittimesFile: file })
                  return false
                }}
                onRemove={() => {
                  this.setState({ splittimesFile: null })
                }}
                fileList={splittimesFile ? [splittimesFile] : null}
                multiple={false}
                accept=".csv"
              >
                <Button size="small">Auswählen</Button>
              </Upload>
              <Button
                type="primary"
                icon={<UploadOutlined />}
                size="small"
                disabled={!splittimesFile}
                onClick={this.handleUploadSplitTimes}
                loading={loading}
              >
                Hochladen
              </Button>
            </Form.Item>
          )}
        </Form>
        <FotorahmenEinstellungenModal
          visible={fotorahmenBearbeitenModalVisible}
          bewerb={bewerb}
          bewerbe={bewerbe}
          onClose={() => {
            this.setState({ fotorahmenBearbeitenModalVisible: false })
          }}
        />
      </Card>
    )
  }
}

SubeventBewerbCard.propTypes = {
  bewerb: PropTypes.shape({
    bewerb_name: PropTypes.any,
    id: PropTypes.any
  }),
  bewerbe: PropTypes.any,
  mode: PropTypes.string,
  onSuccess: PropTypes.func,
  subevent: PropTypes.shape({
    id: PropTypes.any
  })
}

export default SubeventBewerbCard
