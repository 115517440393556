import React, { Component } from 'react'
import { Card, Form, Input, Button, Alert, message } from 'antd'
import { SaveFilled } from '@ant-design/icons'
import { filter } from 'rxjs/operators'
import { setConfigValueForKey, getConfigValueForKey } from '../../../api/config'
import {
  NotificationSubject,
  NotificationNames
} from '../../../utils/NotificationManagement'

class EinstellungenFotoImport extends Component {
  state = {
    loading: false,
    error: null,
    photoPath: null
  }

  handleSaveClick = async () => {
    this.setState({ loading: true })
    const { photoPath } = this.state

    const { success, error, message: m } = (
      await setConfigValueForKey('FILE_PATH_FOR_NEW_PHOTOS', photoPath)
    ).data

    this.setState({ loading: false })

    if (success) {
      message.success(m)
    } else {
      message.error(error)
    }
  }

  getCurrentPhotoPath = async () => {
    this.setState({ loading: true })
    const { success, error, config_value: configValue } = (
      await getConfigValueForKey('FILE_PATH_FOR_NEW_PHOTOS')
    ).data

    if (success) {
      this.setState({ loading: false, photoPath: configValue })
    } else {
      this.setState({ loading: false, error })
    }
  }

  componentDidMount = () => {
    this.getCurrentPhotoPath()

    this.unsubscribeFromNotifications = NotificationSubject.pipe(
      filter(
        (f) =>
          f.notificationName ===
          NotificationNames.userSelectedAtpEventNotification
      )
    ).subscribe(() => {
      // this.loadUntaggedPhotos();
      this.loadTaggedPhotos()
      this.loadStartNumbersOfEvent()
    })
  }

  componentWillUnmount = () => {
    this.unsubscribeFromNotifications.unsubscribe()
  }

  render() {
    const { loading, error, photoPath } = this.state

    return (
      <Card
        title="Einstellungen für Foto-Import"
        extra={
          <Button
            type="primary"
            icon={<SaveFilled />}
            onClick={this.handleSaveClick}
            loading={loading}
          >
            Speichern
          </Button>
        }
      >
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '12px' }}
          />
        )}
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Form.Item
            label="Pfad für neue Fotos"
            help="Der Pfad, wo nach neuen Fotos gesucht werden soll."
          >
            <Input
              placeholder="z.B. /home/user/Desktop/fotos"
              value={photoPath}
              onChange={(e) => {
                this.setState({ photoPath: e.target.value })
              }}
            />
          </Form.Item>
        </Form>
      </Card>
    )
  }
}

export default EinstellungenFotoImport
