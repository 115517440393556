import React, { Component } from 'react'
import {
  Card,
  List,
  Avatar,
  Button,
  message,
  Popconfirm,
  Spin,
  Alert,
  Checkbox
} from 'antd'
import {
  DeleteOutlined,
  PlusCircleFilled,
  MoreOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { filter } from 'rxjs/operators'
import {
  cameraPositionsForEvent,
  deleteCameraPosition,
  updateCameraPositionTaggingMode
} from '../../../../api/cameraPosition'
import { getSelectedAtpEvent } from '../../../../utils/AtpEventManagement'
import {
  NotificationSubject,
  NotificationNames
} from '../../../../utils/NotificationManagement'
import KameraPositionHinzufuegenModal from './KameraPositionHinzufuegenModal'
import WeitereKameraEinstellungenModal from './WeitereKameraEinstellungenModal'

class EinstellungenKameraPositionen extends Component {
  state = {
    loading: false,
    error: null,
    cameraPositions: [],
    addCameraPositionModalVisible: false,
    selectedCameraPositionForConfig: null
  }

  constructor(props) {
    super(props)
    this.unsubscribeFromNotifications = NotificationSubject.pipe(
      filter(
        (f) =>
          f.notificationName ===
          NotificationNames.userSelectedAtpEventNotification
      )
    ).subscribe(() => {
      this.loadCameraPositions()
    })
  }

  loadCameraPositions = async () => {
    const event = getSelectedAtpEvent()

    if (event) {
      this.setState({ loading: true })
      const { success, error, cameraPositions } = (
        await cameraPositionsForEvent(event.id)
      ).data

      if (success) {
        this.setState({ loading: false, cameraPositions })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  handleAddCameraPositionClick = () => {
    this.setState({ addCameraPositionModalVisible: true })
  }

  handleCloseAddCameraPositionModal = () => {
    this.setState({ addCameraPositionModalVisible: false })
  }

  handleAddCameraPositionSuccess = (msg) => {
    this.setState({ addCameraPositionModalVisible: false }, () => {
      this.loadCameraPositions()
      message.success(msg)
    })
  }

  handleDeleteCameraPositionClick = async (cameraPositionId) => {
    this.setState({ loading: true })
    const {
      success,
      message: m,
      error
    } = (await deleteCameraPosition(cameraPositionId)).data

    if (success) {
      this.setState({ loading: false }, this.loadCameraPositions)
      message.success(m)
    } else {
      this.setState({ loading: false, error })
    }
  }

  handleAutomaticTaggingChange = async (cameraPosition, type, enabled) => {
    this.setState({ loading: true, error: null })
    const {
      success,
      message: m,
      error
    } = (
      await updateCameraPositionTaggingMode(cameraPosition.id, type, enabled)
    ).data

    if (success) {
      this.setState({ loading: false }, this.loadCameraPositions)
      message.success(m)
    } else {
      this.setState({ loading: false, error })
    }
  }

  componentDidMount = () => {
    this.loadCameraPositions()
  }

  componentWillUnmount = () => {
    this.unsubscribeFromNotifications.unsubscribe()
  }

  render() {
    const {
      loading,
      error,
      cameraPositions,
      addCameraPositionModalVisible,
      selectedCameraPositionForConfig
    } = this.state

    return (
      <Card
        title="Einstellungen für Kamera-Positionen"
        extra={
          <Button
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={this.handleAddCameraPositionClick}
          >
            Kamera-Position hinzufügen
          </Button>
        }
      >
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '12px' }}
          />
        )}
        {cameraPositions && cameraPositions.length > 0 && (
          <Spin spinning={loading}>
            <List
              size="small"
              header="Vorhandene Kamera-Positionen"
              dataSource={cameraPositions.sort(
                (c1, c2) =>
                  Number(c1.position_number) - Number(c2.position_number)
              )}
              renderItem={(cp) => (
                <List.Item
                  actions={[
                    <Button
                      key="more"
                      icon={<MoreOutlined />}
                      type="link"
                      style={{ margin: 0, padding: 0 }}
                      onClick={() => {
                        this.setState({ selectedCameraPositionForConfig: cp })
                      }}
                    >
                      weitere Einstellungen
                    </Button>,
                    <Checkbox
                      key="time"
                      style={{ margin: 0, padding: 0 }}
                      onChange={(e) => {
                        this.handleAutomaticTaggingChange(
                          cp,
                          'time',
                          e.target.checked
                        )
                      }}
                      checked={parseInt(cp.automatic_time_tagging, 10) === 1}
                    >
                      Zeit Tagging
                    </Checkbox>,
                    <Checkbox
                      key="ai"
                      style={{ margin: 0, padding: 0 }}
                      onChange={(e) => {
                        this.handleAutomaticTaggingChange(
                          cp,
                          'ai',
                          e.target.checked
                        )
                      }}
                      checked={parseInt(cp.automatic_ai_tagging, 10) === 1}
                    >
                      AI Tagging
                    </Checkbox>,
                    <Popconfirm
                      key="delete"
                      title="Diese Kamera-Position endgültig löschen?"
                      okText="Löschen"
                      okButtonProps={{ danger: true }}
                      onConfirm={() => {
                        this.handleDeleteCameraPositionClick(cp.id)
                      }}
                      icon={<DeleteOutlined />}
                      disabled={
                        cp.imported_photos_count && cp.imported_photos_count > 0
                      }
                    >
                      <Button
                        size="small"
                        shape="circle-outline"
                        danger
                        icon={<DeleteOutlined />}
                        disabled={
                          cp.imported_photos_count &&
                          cp.imported_photos_count > 0
                        }
                      />
                    </Popconfirm>
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar size="large" shape="circle">
                        #{cp.position_number}
                      </Avatar>
                    }
                    title={cp.position_description}
                    description={`${cp.imported_photos_count} Fotos importiert / ${cp.tagged_count} Fotos getaggt`}
                  />
                  {parseInt(cp.automatic_time_tagging, 10) === 1 && (
                    <Link to={`/zeit-tagging-settings/${cp.id}`}>
                      <Button type="link">Zum Zeit-Tagging</Button>
                    </Link>
                  )}
                  {parseInt(cp.automatic_ai_tagging, 10) === 1 && (
                    <Link to={`/cloud-tagging/${cp.id}`}>
                      <Button type="link">Zum Cloud-Tagging</Button>
                    </Link>
                  )}
                  {parseInt(cp.automatic_ai_tagging, 10) === 0 &&
                    parseInt(cp.automatic_time_tagging, 10) === 0 && (
                      <Link to={`/fotos-durchsehen/${cp.id}`}>
                        <Button type="link">Fotos durchsehen</Button>
                      </Link>
                    )}
                </List.Item>
              )}
            />
          </Spin>
        )}
        <KameraPositionHinzufuegenModal
          visible={addCameraPositionModalVisible}
          onCancel={this.handleCloseAddCameraPositionModal}
          onSuccess={this.handleAddCameraPositionSuccess}
        />
        <WeitereKameraEinstellungenModal
          visible={selectedCameraPositionForConfig != null}
          cameraPosition={selectedCameraPositionForConfig}
          onCancel={() => {
            this.setState({ selectedCameraPositionForConfig: null })
          }}
        />
      </Card>
    )
  }
}

export default EinstellungenKameraPositionen
