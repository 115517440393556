import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert, Button, Form, Input, Modal } from 'antd'
import { createUser } from '../../api/user'

class NeuerUserModal extends Component {
  state = {
    loading: false,
    error: null,
    alias: null,
    firstName: null,
    lastName: null,
    email: null,
    password: null
  }

  getRandomPassword = () => {
    let pass = ''
    let str =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz0123456789@#$'

    for (let i = 1; i <= 8; i++) {
      let char = Math.floor(Math.random() * str.length + 1)

      pass += str.charAt(char)
    }

    return pass
  }

  handleSaveUser = async () => {
    this.setState({ loading: true, error: null })
    const { alias, firstName, lastName, email, password } = this.state

    const {
      success,
      error,
      message: m,
      userId
    } = (await createUser(alias, firstName, lastName, email, password)).data

    if (success) {
      const { onSuccess } = this.props
      this.setState({ loading: false }, () => {
        onSuccess(m, userId)
      })
    } else {
      this.setState({ loading: false, error })
    }
  }

  render() {
    const { loading, error, alias, firstName, lastName, email, password } =
      this.state
    const { visible, onClose } = this.props
    return (
      <Modal
        visible={visible}
        onCancel={onClose}
        title="Neuen Benutzer erstellen"
        width={600}
        onOk={this.handleSaveUser}
        confirmLoading={loading}
        okText="Benutzer erstellen"
      >
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '24px' }}
          />
        )}
        <Form wrapperCol={{ span: 15 }} labelCol={{ span: 9 }}>
          <Form.Item label="Benutzername">
            <Input
              value={alias}
              onChange={(e) => {
                this.setState({ alias: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item label="Vorname">
            <Input
              value={firstName}
              onChange={(e) => {
                this.setState({ firstName: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item label="Nachname">
            <Input
              value={lastName}
              onChange={(e) => {
                this.setState({ lastName: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item label="E-Mail">
            <Input
              value={email}
              onChange={(e) => {
                this.setState({ email: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item
            label="Passwort"
            help={
              <Button
                type="link"
                size="small"
                onClick={() => {
                  this.setState({ password: this.getRandomPassword() })
                }}
              >
                Passwort generieren
              </Button>
            }
          >
            <Input
              value={password}
              onChange={(e) => {
                this.setState({ password: e.target.value })
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

NeuerUserModal.propTypes = {
  onClose: PropTypes.any,
  onSuccess: PropTypes.func,
  visible: PropTypes.any
}

export default NeuerUserModal
