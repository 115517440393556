import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Modal, Alert, List, Input, message } from 'antd'
import {
  getCameraPositionConfig,
  updateCameraPositionConfig
} from '../../../../api/cameraPosition'

class WeitereKameraEinstellungenModal extends Component {
  state = { loading: false, error: null, config: [] }

  loadCameraPositionConfig = async () => {
    const { cameraPosition } = this.props

    if (cameraPosition) {
      this.setState({ loading: true, error: null })
      const { success, error, cameraPositionConfig } = (
        await getCameraPositionConfig(cameraPosition.id)
      ).data

      if (success) {
        this.setState({ loading: false, config: cameraPositionConfig })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  handleSaveClick = async () => {
    this.setState({ loading: true, error: null })
    const { cameraPosition, onCancel } = this.props
    const { config } = this.state

    const {
      success,
      error,
      message: m
    } = (await updateCameraPositionConfig(cameraPosition.id, config)).data

    if (success) {
      this.setState({ loading: false }, () => {
        message.success(m)
        onCancel()
      })
    } else {
      this.setState({ loading: false, error })
    }
  }

  componentDidUpdate = ({ cameraPosition: prevCameraPosition }) => {
    const { cameraPosition } = this.props

    // wenn die kamera position gesetzt ist und davor entweder keine war, oder von der vorigen abweicht,
    // dann die daten neu laden
    if (
      cameraPosition &&
      (!prevCameraPosition || prevCameraPosition.id !== cameraPosition.id)
    ) {
      this.loadCameraPositionConfig()
    }
  }

  render() {
    const { visible, cameraPosition, onCancel } = this.props
    const { loading, error, config } = this.state

    return (
      <Modal
        visible={visible}
        confirmLoading={loading}
        title={`Erweiterte Einstellungen für Kamera-Position #${
          cameraPosition ? cameraPosition.position_number : ''
        }`}
        width={700}
        centered
        onCancel={onCancel}
        okText="Speichern"
        onOk={this.handleSaveClick}
      >
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '12px' }}
          />
        )}
        <List size="small">
          <List.Item
            actions={[
              <Input
                key="header"
                value={config.time_measurement_column_header || ''}
                placeholder="Spaltenüberschrift"
                onChange={(e) => {
                  const text = e.target.value
                  this.setState((state) => ({
                    config: {
                      ...state.config,
                      time_measurement_column_header: text
                    }
                  }))
                }}
              />
            ]}
          >
            Spalte für die Zeitmessung
          </List.Item>
        </List>
      </Modal>
    )
  }
}

WeitereKameraEinstellungenModal.propTypes = {
  cameraPosition: PropTypes.shape({
    id: PropTypes.any,
    position_number: PropTypes.any
  }),
  onCancel: PropTypes.func,
  visible: PropTypes.any
}

export default WeitereKameraEinstellungenModal
