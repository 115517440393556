import { apiGet, apiPost } from '../api'

/* GET */
export const getEventForCameraPosition = (cameraPositionId) =>
  apiGet('cameraPosition', 'event', { cameraPositionId })
export const cameraPositionsForEvent = (eventId) =>
  apiGet('cameraPosition', 'cameraPositionsForEvent', { eventId })

export const deleteCameraPosition = (cameraPositionId) =>
  apiGet('cameraPosition', 'deleteCameraPosition', { cameraPositionId })

export const getCameraPositionConfig = (cameraPositionId) =>
  apiGet('cameraPosition', 'cameraPositionConfig', { cameraPositionId })

export const getPhotosPaginated = (cameraPositionId, offset, limit) =>
  apiGet('cameraPosition', 'getPhotosPaginated', {
    cameraPositionId,
    offset,
    limit
  })

export const getPhotosInTimespan = (cameraPositionId, from, to) =>
  apiGet('cameraPosition', 'getPhotosInTimespan', {
    cameraPositionId,
    from,
    to
  })

export const getCameraPositionInfo = (cameraPositionId) =>
  apiGet('cameraPosition', 'getInfo', { cameraPositionId })

/* POST */
export const addCameraPositionForEvent = (eventId, number, description) =>
  apiPost('cameraPosition', 'addCameraPositionForEvent', {
    eventId,
    description,
    number
  })

export const updateCameraPositionTaggingMode = (
  cameraPositionId,
  type,
  enabled
) => {
  if (type.toLocaleLowerCase() === 'time') {
    return apiPost('cameraPosition', 'editCameraPositionTaggingMode', {
      cameraPositionId,
      automaticTimeTagging: enabled ? 1 : 0
    })
  }

  return apiPost('cameraPosition', 'editCameraPositionTaggingMode', {
    cameraPositionId,
    automaticAiTagging: enabled ? 1 : 0
  })
}

export const updateCameraPositionConfig = (cameraPositionId, config) =>
  apiPost('cameraPosition', 'updateCameraPositionConfig', {
    cameraPositionId,
    cameraPositionConfig: config
  })
