import { apiGet, apiPost } from '../api'

/** GET */
export const getAllUsers = () => apiGet('user', 'all')

export const getEventsWithAccess = (userId) =>
  apiGet('user', 'eventsWithAccess', { userId })

/** POST */
export const toggleActive = (userId, active) =>
  apiPost('user', 'toggleActive', { userId, active: active ? 1 : 0 })

export const toggleAdmin = (userId, isAdmin) =>
  apiPost('user', 'toggleAdmin', { userId, isAdmin: isAdmin ? 1 : 0 })

export const updateUserAccessToEvents = (userId, eventIds) =>
  apiPost('user', 'updateUserAccessToEvents', { userId, eventIds })

export const createUser = (alias, firstName, lastName, email, password) =>
  apiPost('user', 'createUser', { alias, firstName, lastName, email, password })
