import PropTypes from 'prop-types'
import React from 'react'
import { Menu } from 'antd'
import {
  SettingOutlined,
  ClockCircleOutlined,
  CameraOutlined,
  EnvironmentOutlined
} from '@ant-design/icons'

const EinstellungenMenu = ({ activeKey, onMenuClick, width }) => {
  return (
    <Menu
      onClick={onMenuClick}
      style={{ width: width || '250px' }}
      selectedKeys={[activeKey || 'allgemein']}
      defaultSelectedKeys={['allgemein']}
      mode="inline"
    >
      <Menu.Item key="allgemein" icon={<SettingOutlined />}>
        Allgemein
      </Menu.Item>
      <Menu.Item key="zeitmessung" icon={<ClockCircleOutlined />}>
        Zeitmessung
      </Menu.Item>
      <Menu.Item key="foto-import" icon={<CameraOutlined />}>
        Foto-Import
      </Menu.Item>
      <Menu.Item key="kamera-positionen" icon={<EnvironmentOutlined />}>
        Kamera-Positionen
      </Menu.Item>
    </Menu>
  )
}

EinstellungenMenu.propTypes = {
  activeKey: PropTypes.string,
  onMenuClick: PropTypes.any,
  width: PropTypes.string
}

export default EinstellungenMenu
