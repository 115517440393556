import { apiGet, apiPost, apiPostFormData } from '../api'

/** GET */
export const getEventsPaginated = (offset, limit) =>
  apiGet('event', 'eventsPaginated', { offset, limit })

export const getAllEvents = () => apiGet('event', 'all')

export const getEventInfo = (eventId) =>
  apiGet('event', 'eventInfo', { eventId })

export const getSubeventBewerbe = (eventId) =>
  apiGet('event', 'subeventBewerbe', { eventId })

export const getSubeventFotorahmenSettings = (bewerbId) =>
  apiGet('event', 'subeventFotorahmenSettings', { bewerbId })

/** POST */
export const createEvent = (
  eventName,
  eventStartDate,
  eventEndDate,
  eventDescription,
  urlKeywords,
  eventLocation,
  eventCountry,
  eventWebsite,
  showOnWebsite,
  coverImage,
  eventLogo,
  fotorahmenHoch,
  fotorahmenQuer
) => {
  const formData = new FormData()
  formData.append('eventName', eventName)
  formData.append('eventStartDate', eventStartDate)
  formData.append('eventEndDate', eventEndDate)
  formData.append('eventDescription', eventDescription)
  formData.append('urlKeywords', urlKeywords)
  formData.append('eventLocation', eventLocation)
  formData.append('eventCountry', eventCountry)
  formData.append('eventWebsite', eventWebsite)
  formData.append('showOnWebsite', showOnWebsite ? 1 : 0)
  formData.append('coverImage', coverImage)
  formData.append('eventLogo', eventLogo)
  formData.append('fotorahmenHoch', fotorahmenHoch)
  formData.append('fotorahmenQuer', fotorahmenQuer)

  return apiPostFormData('event', 'createEvent', formData)
}

export const createSubEvent = (
  eventId,
  eventName,
  eventStartDate,
  eventEndDate,
  urlKeywords,
  showOnWebsite
) =>
  apiPost('event', 'createSubEvent', {
    eventId,
    eventName,
    eventStartDate,
    eventEndDate,
    urlKeywords,
    showOnWebsite: showOnWebsite ? 1 : 0
  })

export const createSubeventBewerb = (
  eventId,
  bewerbName,
  fotorahmenGesamtHoch,
  fotorahmenGesamtQuer,
  fotorahmenSplitHoch,
  fotorahmenSplitQuer
) => {
  const formData = new FormData()
  formData.append('eventId', eventId)
  formData.append('bewerbName', bewerbName)
  formData.append('fotorahmenGesamtHoch', fotorahmenGesamtHoch)
  formData.append('fotorahmenGesamtQuer', fotorahmenGesamtQuer)
  formData.append('fotorahmenSplitHoch', fotorahmenSplitHoch)
  formData.append('fotorahmenSplitQuer', fotorahmenSplitQuer)

  return apiPostFormData('event', 'createSubeventBewerb', formData)
}

export const updateSubeventBewerb = (
  bewerbId,
  bewerbName,
  fotorahmenGesamtHoch,
  fotorahmenGesamtQuer,
  fotorahmenSplitHoch,
  fotorahmenSplitQuer
) => {
  const formData = new FormData()
  formData.append('bewerbId', bewerbId)
  formData.append('bewerbName', bewerbName)
  formData.append('fotorahmenGesamtHoch', fotorahmenGesamtHoch)
  formData.append('fotorahmenGesamtQuer', fotorahmenGesamtQuer)
  formData.append('fotorahmenSplitHoch', fotorahmenSplitHoch)
  formData.append('fotorahmenSplitQuer', fotorahmenSplitQuer)

  return apiPostFormData('event', 'updateSubeventBewerb', formData)
}

export const updateEvent = (
  eventId,
  eventName,
  eventStartDate,
  eventEndDate,
  eventDescription,
  urlKeywords,
  eventLocation,
  eventCountry,
  eventWebsite,
  showOnWebsite,
  coverImage,
  eventLogo,
  fotorahmenHoch,
  fotorahmenQuer
) => {
  const formData = new FormData()
  formData.append('eventId', eventId)
  formData.append('eventName', eventName)
  formData.append('eventStartDate', eventStartDate)
  formData.append('eventEndDate', eventEndDate)
  formData.append('eventDescription', eventDescription)
  formData.append('urlKeywords', urlKeywords)
  formData.append('eventLocation', eventLocation)
  formData.append('eventCountry', eventCountry)
  formData.append('eventWebsite', eventWebsite)
  formData.append('showOnWebsite', showOnWebsite ? 1 : 0)
  formData.append('coverImage', coverImage)
  formData.append('eventLogo', eventLogo)
  formData.append('fotorahmenHoch', fotorahmenHoch)
  formData.append('fotorahmenQuer', fotorahmenQuer)

  return apiPostFormData('event', 'updateEvent', formData)
}

export const deleteSubeventBewerb = (bewerbId) =>
  apiPost('event', 'deleteSubeventBewerb', { bewerbId })

export const toggleShowOnWebsite = (eventId, showOnWebsite) =>
  apiPost('event', 'toggleShowOnWebsite', {
    eventId,
    showOnWebsite: showOnWebsite ? 1 : 0
  })

export const toggleReadyForSelling = (eventId, readyForSelling) =>
  apiPost('event', 'toggleReadyForSelling', {
    eventId,
    readyForSelling: readyForSelling ? 1 : 0
  })

export const updateSubeventFotorahmenSettings = (
  bewerbId,
  fotorahmenSettings
) =>
  apiPost('event', 'updateSubeventFotorahmenSettings', {
    bewerbId,
    settings: fotorahmenSettings
  })

export const queuePhotosReadyMailsForSubevent = (eventId) =>
  apiPost('event', 'queuePhotosReadyMails', { eventId })

export const queueReminderMailsForSubevent = (eventId) =>
  apiPost('event', 'queueReminderMails', { eventId })
