import React, { Component } from 'react'
import { Row, Col, Card, Typography } from 'antd'
import EventTeilnehmerAnzahl from '../Widgets/EventTeilnehmerAnzahl'
import ImportierteUndGetaggteFotos from '../Widgets/ImportierteUndGetaggteFotos'
import LetzteStripeZahlungenWidget from '../Widgets/LetzteStripeZahlungen'
import UserManagement from '../../utils/UserManagement'
import { redirect } from 'react-router-dom'

class Dashboard extends Component {
  state = {
    loading: false,
    error: null
  }

  componentDidMount = () => {
    if (!UserManagement.isAdmin()) {
      redirect('/photo-tagging')
    }
  }

  render() {
    return (
      <>
        <Typography.Title level={1}>Dashboard</Typography.Title>
        <Row gutter={24}>
          <Col
            xs={24}
            lg={8}
            xxl={6}
            style={{ marginTop: '6px', marginBottom: '6px' }}
          >
            <Card size="small">
              <ImportierteUndGetaggteFotos />
            </Card>
          </Col>
          <Col
            xs={24}
            lg={8}
            xxl={6}
            style={{ marginTop: '6px', marginBottom: '6px' }}
          >
            <Card size="small">
              <EventTeilnehmerAnzahl />
            </Card>
          </Col>
          <Col
            xs={24}
            lg={16}
            xxl={12}
            style={{ marginTop: '6px', marginBottom: '6px' }}
          >
            <Card size="small" title="Letzte Zahlungseingänge Stripe">
              <LetzteStripeZahlungenWidget />
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default Dashboard
