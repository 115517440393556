import { apiGet, apiPost, apiPostWithFiles } from '../api'

export const getUntaggedPhotosOfEvent = (eventId) =>
  apiGet('photoImport', 'untaggedPhotosOfEvent', { eventId })

export const getTaggedPhotosOfEvent = (eventId, offset, limit) =>
  apiGet('photoImport', 'taggedPhotosOfEvent', { eventId, offset, limit })

export const getTaggedPhotoCountOfEvent = (eventId) =>
  apiGet('photoImport', 'taggedPhotoCountForEvent', { eventId })

export const getUnimportedPhotosCount = () =>
  apiGet('photoImport', 'getUnimportedPhotosCount')

export const startPhotoImport = (cameraPositionId) =>
  apiGet('photoImport', 'startPhotoImport', { cameraPositionId })

export const getImportedPhotoCountOfEvent = (eventId) =>
  apiGet('photoImport', 'importedPhotoCountForEvent', { eventId })

export const getTaggedStartNumbersForPhoto = (photoId) =>
  apiGet('photoImport', 'getTaggedStartNumbersForPhoto', { photoId })

export const updateRecognizedStartNumbersOfPhoto = (photoId, startNumbers) =>
  apiPost('photoImport', 'updateRecognizedStartNumbersOfPhoto', {
    photoId,
    startNumbers
  })

export const getPhotosOfTimeAndCamera = (time, cameraId) =>
  apiGet('photoImport', 'getPhotosOfTimeAndCamera', {
    timeForPhotos: time,
    cameraForPhotos: cameraId
  })

/**
 * POST
 */

export const uploadRawPhoto = (cameraId, file, originalPhotoDate) =>
  apiPostWithFiles('photoImport', 'uploadRawPhoto', {
    cameraPositionId: cameraId,
    rawPhoto: file,
    originalPhotoDate
  })
