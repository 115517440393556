import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Modal, Form, InputNumber, Alert, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { picthisApiPost } from '../../api/picthisApi'
import { getSelectedAtpEvent } from '../../utils/AtpEventManagement'

class PicthisTeilnehmerlisteHochladenModal extends Component {
  state = { loading: false, error: null, picthisEventId: null }

  handleUploadClick = async () => {
    this.setState({ loading: true, error: null })
    const { picthisEventId } = this.state
    const { onSuccess } = this.props
    const event = getSelectedAtpEvent()
    if (event) {
      const {
        success,
        error,
        message: m
      } = (
        await picthisApiPost('participant', 'uploadParticipantsForEvent', {
          picthisEventId,
          eventId: event.id
        })
      ).data

      if (success) {
        message.success(m)
        this.setState({ loading: false }, onSuccess)
      } else {
        this.setState({ loading: false, error })
      }
    } else {
      message.warn('Kein Event ausgewählt.')
      this.setState({ loading: false })
    }
  }

  render() {
    const { loading, error, picthisEventId } = this.state
    const { visible, onCancel } = this.props

    return (
      <Modal
        visible={visible}
        title="Teilnehmerliste zu picthis hochladen"
        centered
        width={500}
        onCancel={onCancel}
        onOk={this.handleUploadClick}
        confirmLoading={loading}
        okText="Hochladen zu picthis"
        okButtonProps={{ icon: <UploadOutlined /> }}
      >
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '12px' }}
          />
        )}
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Form.Item label="Event ID bei picthis" required>
            <InputNumber
              min={1}
              step={1}
              value={picthisEventId}
              onChange={(value) => {
                this.setState({ picthisEventId: value })
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

PicthisTeilnehmerlisteHochladenModal.propTypes = {
  onCancel: PropTypes.any,
  onSuccess: PropTypes.any,
  visible: PropTypes.any
}

export default PicthisTeilnehmerlisteHochladenModal
