let loggedInUser = null

const setLoggedInUser = (user) => {
  loggedInUser = user
}

const getLoggedInUser = () => loggedInUser

const userHasThumbnail = () => loggedInUser && loggedInUser.thumbnail

const userThumbnail = () => (loggedInUser && loggedInUser.thumbnail) || null

const userHasPermission = (perms) => {
  if (Array.isArray(perms)) {
    // mehrere permissions übergeben
    let hasPerm = false
    perms.forEach((p) => {
      if (userHasPermission(p)) {
        hasPerm = true
      }
    })

    return hasPerm
  }
  // else wenn kein array, dann nur eine einzelne permission abfragen
  return loggedInUser && loggedInUser.permissions[perms] === true
}

const userHasRole = (roles) => {
  if (Array.isArray(roles)) {
    // mehrere permissions übergeben
    let hasRole = false
    roles.forEach((r) => {
      if (userHasRole(r)) {
        hasRole = true
      }
    })

    return hasRole
  }
  // else wenn kein array, dann nur eine einzelne rolle abfragen
  return loggedInUser && loggedInUser.role.role_alias === roles
}

const getName = () =>
  (loggedInUser && `${loggedInUser.first_name} ${loggedInUser.last_name}`) ||
  null

const getFirstName = () => (loggedInUser && loggedInUser.first_name) || null
const getUserAlias = () => (loggedInUser && loggedInUser.alias) || null
const getUserId = () =>
  loggedInUser && loggedInUser.id ? parseInt(loggedInUser.id, 10) : null
const isAdmin = () =>
  loggedInUser ? Number(loggedInUser.is_admin) === 1 : false

const getCurrentDynamicData = () =>
  (loggedInUser && loggedInUser.currentDynamicData) || null

export default {
  setLoggedInUser,
  userHasPermission,
  userHasRole,
  getName,
  getFirstName,
  getUserAlias,
  getUserId,
  userHasThumbnail,
  userThumbnail,
  getLoggedInUser,
  getCurrentDynamicData,
  isAdmin
}
