import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  message,
  PageHeader,
  Row,
  Spin,
  Statistic
} from 'antd'
import {
  getCameraPositionInfo,
  getPhotosPaginated
} from '../../api/cameraPosition'
import {
  ArrowRightOutlined,
  LoadingOutlined,
  PauseOutlined
} from '@ant-design/icons'
import CloudTaggingSocket from './CloudTaggingSocket'
import moment from 'moment'
import { withRouter } from '../../withRouter'

class CloudTagging extends Component {
  state = {
    loading: false,
    error: null,
    camera: null,
    connectedToTaggingWebsocket: false,
    taggingIsActive: false,
    remaining: 0,
    photos: [],
    currentPage: 1
  }

  loadCameraInfos = async () => {
    const { params } = this.props
    const { cameraId } = params

    if (cameraId) {
      this.setState({ loading: true, error: null })
      const { success, error, cameraPosition } = (
        await getCameraPositionInfo(cameraId)
      ).data

      if (success) {
        this.setState({ loading: false, camera: cameraPosition })
      } else {
        this.setState({ loading: false, error })
        message.error(error)
      }
    }
  }

  loadImagesPaginated = async () => {
    const { params } = this.props
    const { cameraId } = params

    if (cameraId) {
      this.setState({ loading: true })
      const { currentPage } = this.state

      const { success, error, photos } = (
        await getPhotosPaginated(cameraId, (currentPage - 1) * 25, 25)
      ).data

      if (success) {
        this.setState({ loading: false, photos })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  connectToTaggingWebsocket = () => {
    CloudTaggingSocket.connect(this.cloudTaggingSocketReceivedMsg).then(() => {
      this.setState({ connectedToTaggingWebsocket: true })
      CloudTaggingSocket.sendMessage({ cmd: 'status' })
      CloudTaggingSocket.sendMessage({ cmd: 'progress' })
    })
  }

  cloudTaggingSocketReceivedMsg = (msg) => {
    try {
      const json = JSON.parse(msg)
      if (json.cmd) {
        if (json.cmd === 'status') {
          this.setState({ taggingIsActive: json.running || false })
        } else if (json.cmd === 'cloudTagging') {
          this.setState({ remaining: json.remaining })
        } else if (json.cmd === 'stop') {
          this.setState({ taggingIsActive: false })
        }
      }
    } catch {
      // nix
    }
  }

  toggleTaggingActive = () => {
    const { taggingIsActive } = this.state
    const { params } = this.props
    const { cameraId } = params

    if (taggingIsActive) CloudTaggingSocket.sendMessage({ cmd: 'stop' })
    else {
      CloudTaggingSocket.sendMessage({ cmd: 'start', cameraId })
      CloudTaggingSocket.sendMessage({ cmd: 'progress' })
      this.setState({ taggingIsActive: true })
    }
  }

  componentDidMount = () => {
    this.loadCameraInfos()
    this.connectToTaggingWebsocket()
    this.loadImagesPaginated()

    setInterval(this.loadCameraInfos, 20000)
  }

  render() {
    const {
      loading,
      error,
      camera,
      connectedToTaggingWebsocket,
      taggingIsActive,
      remaining,
      photos,
      currentPage
    } = this.state

    return (
      <>
        <PageHeader
          title={
            camera
              ? `Cloud-Tagging für Kamera "#${camera.position_number} ${camera.position_description}"`
              : 'Cloud-Tagging'
          }
          extra={
            connectedToTaggingWebsocket ? (
              <Badge color="green" text="verbunden" />
            ) : (
              <Badge color="red" text="nicht verbunden" />
            )
          }
        >
          <Card>
            <Spin spinning={loading}>
              {error && (
                <Alert
                  type="error"
                  showIcon
                  message={error}
                  style={{ marginBottom: '24px' }}
                />
              )}
              <Row gutter={48}>
                <Col xs={24} lg={4}>
                  <Statistic
                    title="Importierte Fotos"
                    value={camera ? camera.import_count : '-'}
                  />
                  <Statistic
                    title="Getaggte Fotos"
                    value={camera ? camera.tagged_count : '-'}
                  />
                </Col>
                <Col xs={24} lg={20}>
                  <Button
                    type="primary"
                    icon={
                      taggingIsActive ? (
                        <PauseOutlined />
                      ) : (
                        <ArrowRightOutlined />
                      )
                    }
                    onClick={this.toggleTaggingActive}
                  >
                    {taggingIsActive
                      ? 'Tagging stoppen'
                      : 'Tagging über Google starten'}
                  </Button>
                  {taggingIsActive && (
                    <Statistic
                      title="Verbleibende Fotos zum Taggen"
                      value={remaining}
                      suffix={
                        <LoadingOutlined spin style={{ marginLeft: '6px' }} />
                      }
                    />
                  )}
                </Col>
              </Row>
            </Spin>
          </Card>
          {photos.length > 0 && (
            <>
              <h2 style={{ marginTop: '48px', marginBottom: '0' }}>
                Fotos von dieser Kamera
              </h2>
              <h3>Sortiert nach Uhrzeit</h3>
              <Button
                type="default"
                disabled={currentPage === 1}
                onClick={() => {
                  this.setState(
                    { currentPage: currentPage - 1 },
                    this.loadImagesPaginated
                  )
                }}
              >
                zurück
              </Button>{' '}
              <Button
                type="default"
                onClick={() => {
                  this.setState(
                    { currentPage: currentPage + 1 },
                    this.loadImagesPaginated
                  )
                }}
                disabled={photos.length === 0}
              >
                weiter
              </Button>
            </>
          )}
          {photos.length > 0 && (
            <Row gutter={[24, 24]}>
              {photos.map((i) => (
                <Col md={12} xl={6} key={i.id}>
                  <div style={{ padding: '24px', textAlign: 'center' }}>
                    <a href={i.url} target="_blank" rel="noopener noreferrer">
                      <img
                        key={i.id}
                        src={i.thumbnail_url}
                        title={i.new_filename}
                        alt={i.new_filename}
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'cover',
                          maxHeight: '240px'
                        }}
                      />
                    </a>
                    {moment(i.file_date_time).format('HH:mm:ss')}
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </PageHeader>
      </>
    )
  }
}

CloudTagging.propTypes = {
  params: PropTypes.any
}

export default withRouter(CloudTagging)
