import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Input, message, Modal, Row, Select } from 'antd'
import PropTypes from 'prop-types'
import {
  getSubeventFotorahmenSettings,
  updateSubeventFotorahmenSettings
} from '../../api/event'
import moment from 'moment'
import { SaveOutlined } from '@ant-design/icons'

const FotorahmenEinstellungenModal = ({
  visible,
  onClose,
  bewerb,
  bewerbe
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [fotorahmenSettings, setFotorahmenSettings] = useState({})
  const [gesamtzeitHochPreviewImageKey, setGesamtzeitHochPreviewImageKey] =
    useState([null])
  const [gesamtzeitQuerPreviewImageKey, setGesamtzeitQuerPreviewImageKey] =
    useState([null])
  const [splitzeitHochPreviewImageKey, setSplitzeitHochPreviewImageKey] =
    useState([null])
  const [splitzeitQuerPreviewImageKey, setSplitzeitQuerPreviewImageKey] =
    useState([null])
  const [selectedBewerbToCopy, setSelectedBewerbToCopy] = useState(null)

  async function loadSettings(bewerbId) {
    if (bewerbId || bewerb.id) {
      setLoading(true)
      const { success, error, settings } = (
        await getSubeventFotorahmenSettings(bewerbId || bewerb.id)
      ).data
      setLoading(false)
      if (success) {
        // updateSplitzeitPreviews()
        // updateGesamtzeitPreviews()
        setFotorahmenSettings(settings)
      } else {
        setError(error)
      }
    }
  }

  function updateSplitzeitPreviews() {
    setSplitzeitHochPreviewImageKey(moment().unix())
    setSplitzeitQuerPreviewImageKey(moment().unix())
  }

  function updateGesamtzeitPreviews() {
    setGesamtzeitHochPreviewImageKey(moment().unix())
    setGesamtzeitQuerPreviewImageKey(moment().unix())
  }

  function getValueForSetting(settingKey) {
    if (fotorahmenSettings) return fotorahmenSettings[settingKey]
    return null
  }

  function setValueForSettings(settingKey, settingValue) {
    if (fotorahmenSettings) {
      setFotorahmenSettings({
        ...fotorahmenSettings,
        [settingKey]: settingValue
      })
    }
  }

  async function saveSettings() {
    setLoading(true)
    const {
      success,
      error,
      message: m
    } = (await updateSubeventFotorahmenSettings(bewerb.id, fotorahmenSettings))
      .data

    setLoading(false)

    if (success) {
      updateSplitzeitPreviews()
      updateGesamtzeitPreviews()

      message.success(m)
    } else {
      setError(error)
      message.error(error)
    }
  }

  useEffect(() => {
    loadSettings()
    // eslint-disable-next-line
  }, [visible])

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Fotorahmen bearbeiten"
      width="95%"
      centered
      confirmLoading={loading}
    >
      {error && (
        <Alert
          type="error"
          showIcon
          message={error}
          style={{ marginBottom: '24px' }}
        />
      )}
      <Row gutter={[24, 24]} style={{ marginLeft: 0, marginRight: 0 }}>
        <Col xs={24} md={10}>
          <Row gutter={24}>
            <h3>Fotorahmen hoch</h3>
            <Col xs={24}>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Text X/Y:{' '}
                <Input
                  placeholder="X"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_gesamt_hoch_text_x')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_gesamt_hoch_text_x',
                      e.target.value
                    )
                  }}
                />{' '}
                <Input
                  placeholder="Y"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_gesamt_hoch_text_y')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_gesamt_hoch_text_y',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Schriftgröße (px):{' '}
                <Input
                  placeholder="px"
                  size="small"
                  suffix="px"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_gesamt_hoch_text_size')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_gesamt_hoch_text_size',
                      e.target.value
                    )
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row gutter={24}>
            <h3>Fotorahmen quer</h3>
            <Col xs={24}>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Text X/Y:{' '}
                <Input
                  placeholder="X"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_gesamt_quer_text_x')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_gesamt_quer_text_x',
                      e.target.value
                    )
                  }}
                />{' '}
                <Input
                  placeholder="Y"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_gesamt_quer_text_y')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_gesamt_quer_text_y',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Schriftgröße (px):{' '}
                <Input
                  placeholder="px"
                  size="small"
                  style={{ width: '100px' }}
                  suffix="px"
                  value={getValueForSetting('fotorahmen_gesamt_quer_text_size')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_gesamt_quer_text_size',
                      e.target.value
                    )
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row gutter={24}>
            <h3>Splitzeit hoch</h3>
            <Col xs={24}>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Text 1 X/Y:{' '}
                <Input
                  placeholder="X"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_hoch_text1_x')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_hoch_text1_x',
                      e.target.value
                    )
                  }}
                />{' '}
                <Input
                  placeholder="Y"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_hoch_text1_y')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_hoch_text1_y',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Text 2 X/Y:{' '}
                <Input
                  placeholder="X"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_hoch_text2_x')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_hoch_text2_x',
                      e.target.value
                    )
                  }}
                />{' '}
                <Input
                  placeholder="Y"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_hoch_text2_y')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_hoch_text2_y',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Text 3 X/Y:{' '}
                <Input
                  placeholder="X"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_hoch_text3_x')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_hoch_text3_x',
                      e.target.value
                    )
                  }}
                />{' '}
                <Input
                  placeholder="Y"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_hoch_text3_y')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_hoch_text3_y',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Schriftgröße 1 (px):{' '}
                <Input
                  placeholder="px"
                  size="small"
                  style={{ width: '100px' }}
                  suffix="px"
                  value={getValueForSetting('fotorahmen_split_hoch_text1_size')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_hoch_text1_size',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Schriftgröße 2 (px):{' '}
                <Input
                  placeholder="px"
                  size="small"
                  style={{ width: '100px' }}
                  suffix="px"
                  value={getValueForSetting('fotorahmen_split_hoch_text2_size')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_hoch_text2_size',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Schriftgröße 3 (px):{' '}
                <Input
                  placeholder="px"
                  size="small"
                  style={{ width: '100px' }}
                  suffix="px"
                  value={getValueForSetting('fotorahmen_split_hoch_text3_size')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_hoch_text3_size',
                      e.target.value
                    )
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row gutter={24}>
            <h3>Splitzeit quer</h3>
            <Col xs={24}>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Text 1 X/Y:{' '}
                <Input
                  placeholder="X"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_quer_text1_x')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_quer_text1_x',
                      e.target.value
                    )
                  }}
                />{' '}
                <Input
                  placeholder="Y"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_quer_text1_y')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_quer_text1_y',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Text 2 X/Y:{' '}
                <Input
                  placeholder="X"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_quer_text2_x')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_quer_text2_x',
                      e.target.value
                    )
                  }}
                />{' '}
                <Input
                  placeholder="Y"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_quer_text2_y')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_quer_text2_y',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Text 3 X/Y:{' '}
                <Input
                  placeholder="X"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_quer_text3_x')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_quer_text3_x',
                      e.target.value
                    )
                  }}
                />{' '}
                <Input
                  placeholder="Y"
                  size="small"
                  style={{ width: '100px' }}
                  value={getValueForSetting('fotorahmen_split_quer_text3_y')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_quer_text3_y',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Schriftgröße 1 (px):{' '}
                <Input
                  placeholder="px"
                  size="small"
                  style={{ width: '100px' }}
                  suffix="px"
                  value={getValueForSetting('fotorahmen_split_quer_text1_size')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_quer_text1_size',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Schriftgröße 2 (px):{' '}
                <Input
                  placeholder="px"
                  size="small"
                  style={{ width: '100px' }}
                  suffix="px"
                  value={getValueForSetting('fotorahmen_split_quer_text2_size')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_quer_text2_size',
                      e.target.value
                    )
                  }}
                />
              </Row>
              <Row style={{ flexDirection: 'row', columnGap: '12px' }}>
                Schriftgröße 3 (px):{' '}
                <Input
                  placeholder="px"
                  size="small"
                  style={{ width: '100px' }}
                  suffix="px"
                  value={getValueForSetting('fotorahmen_split_quer_text3_size')}
                  onChange={(e) => {
                    setValueForSettings(
                      'fotorahmen_split_quer_text3_size',
                      e.target.value
                    )
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row
            style={{ marginTop: '48px', flexDirection: 'column' }}
            justify="center"
          >
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={saveSettings}
            >
              Speichern
            </Button>
            {bewerbe && Array.isArray(bewerbe) && bewerbe.length > 0 && (
              <div style={{ marginTop: '12px' }}>
                Oder von Bewerb übernehmen:{' '}
                <Select
                  options={bewerbe.map((b) => ({
                    label: b.bewerb_name,
                    value: b.id
                  }))}
                  placeholder="Bewerb auswählen ... "
                  value={selectedBewerbToCopy ? selectedBewerbToCopy.id : null}
                  onChange={(id) => {
                    console.log('selected:', id)
                    setSelectedBewerbToCopy(
                      bewerbe.find((b) => Number(b.id) === Number(id))
                    )
                  }}
                />
                <Button
                  type="default"
                  onClick={() => {
                    loadSettings(selectedBewerbToCopy.id)
                  }}
                  loading={loading}
                  disabled={!selectedBewerbToCopy}
                  style={{ marginLeft: '6px' }}
                >
                  Einstellungen laden
                </Button>
              </div>
            )}
          </Row>
        </Col>
        <Col xs={24} md={14}>
          {bewerb && (
            <>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <img
                    key={gesamtzeitHochPreviewImageKey}
                    src={`https://api.picthis.one/products/preview/nettozeit/${bewerb.id}/blank/portrait?${gesamtzeitHochPreviewImageKey}`}
                    alt="Nettozeit hoch"
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: '360px',
                      objectFit: 'contain'
                    }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <img
                    key={gesamtzeitQuerPreviewImageKey}
                    src={`https://api.picthis.one/products/preview/nettozeit/${bewerb.id}/blank/landscape?${gesamtzeitQuerPreviewImageKey}`}
                    alt="Nettozeit quer"
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: '360px',
                      objectFit: 'contain'
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={24} style={{ marginTop: '24px' }}>
                <Col xs={24} md={12}>
                  <img
                    key={splitzeitHochPreviewImageKey}
                    src={`https://api.picthis.one/products/preview/urkunde/${bewerb.id}/blank/portrait?${splitzeitHochPreviewImageKey}`}
                    alt="Splitzeit hoch"
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: '360px',
                      objectFit: 'contain'
                    }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <img
                    key={splitzeitQuerPreviewImageKey}
                    src={`https://api.picthis.one/products/preview/urkunde/${bewerb.id}/blank/landscape?${splitzeitQuerPreviewImageKey}`}
                    alt="Splitzeit quer"
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: '360px',
                      objectFit: 'contain'
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Modal>
  )
}

FotorahmenEinstellungenModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  bewerb: PropTypes.object,
  bewerbe: PropTypes.array
}

export default FotorahmenEinstellungenModal
