import React, { Component } from 'react'
import { Card } from 'antd'

class EinstellungenAllgemein extends Component {
  state = {}

  render() {
    return (
      <Card title="Allgemein">
        Hier kommen allgemeine Einstellungen her wie z.B. System-Uhrzeit, wer
        gerade eingeloggt ist, wie viel Speicherplatz frei ist etc.
      </Card>
    )
  }
}

export default EinstellungenAllgemein
