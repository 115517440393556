import Cookies from 'js-cookie'
let socket = null
let onMsgCallback = null

const connect = async (msgCallback) => {
  onMsgCallback = msgCallback
  return new Promise((resolve) => {
    socket = new WebSocket('wss://api.picthis.one:8080')

    socket.onopen = () => {
      console.log('connection opened')
      resolve(true)
    }

    socket.onmessage = (e) => {
      msgCallback(e.data)
    }

    socket.onerror = () => {
      resolve(false)
    }

    socket.onclose = () => {
      console.log('closed')
      resolve(false)
    }
  })
}

const sendMessage = async (msg) => {
  if (socket != null && socket.readyState === WebSocket.OPEN) {
    socket.send(JSON.stringify({ ...msg, sid: Cookies.get('sid') }))
  } else {
    connect(onMsgCallback)
      .then((connected) => {
        if (connected)
          socket.send(JSON.stringify({ ...msg, sid: Cookies.get('sid') }))
      })
      .catch(() => {
        console.log('could not reconnect to socket')
      })
  }
}

export default { connect, sendMessage }
