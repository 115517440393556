import { apiPostWithFiles, apiGet, apiPostFormData } from '../api'

export const getCompleteParticipantListForEvent = (eventId) =>
  apiGet('participantList', 'allForEvent', { eventId })

export const getParticipantList = (eventId, offset, limit) =>
  apiGet('participantList', 'forEvent', { eventId, offset, limit })

export const getCountForEvent = (eventId) =>
  apiGet('participantList', 'countForEvent', { eventId })

export const getStartNumbersOfEvent = (eventId) =>
  apiGet('participantList', 'getStartNumbersOfEvent', { eventId })

export const uploadParticipantList = (
  eventId,
  file,
  deleteAllBeforeInserting
) =>
  apiPostWithFiles('participantList', 'uploadParticipantList', {
    eventId,
    files: [file],
    deleteAllBeforeInserting
  })

export const updateListForSubeventBewerb = (bewerbId, csvFile) => {
  const formData = new FormData()
  formData.append('bewerbId', bewerbId)
  formData.append('csv', csvFile)

  return apiPostFormData('participantList', 'updateForSubeventBewerb', formData)
}

export const updateSplitTimesForSubeventBewerb = (bewerbId, csvFile) => {
  const formData = new FormData()
  formData.append('bewerbId', bewerbId)
  formData.append('csv', csvFile)

  return apiPostFormData(
    'participantList',
    'updateSplitTimesForSubeventBewerb',
    formData
  )
}

export const uploadSplitTimes = (eventId, file) =>
  apiPostWithFiles('splitTimes', 'uploadSplitTimesCsv', {
    eventId,
    files: [file]
  })
