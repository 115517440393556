import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Typography, Alert, Spin } from 'antd'
import { ExclamationOutlined, CheckOutlined } from '@ant-design/icons'
import { filter } from 'rxjs/operators'
import { getSelectedAtpEvent } from '../../utils/AtpEventManagement'
import {
  NotificationSubject,
  NotificationNames
} from '../../utils/NotificationManagement'
import {
  getUntaggedPhotosOfEvent,
  getTaggedPhotosOfEvent,
  getTaggedPhotoCountOfEvent
} from '../../api/photoImport'
import { getStartNumbersOfEvent } from '../../api/participant'
import TaggingModusNachStartnummer from './TaggingModusNachStartnummer'

class FotoTagging extends Component {
  state = {
    loading: false,
    error: null,
    untaggedPhotos: [],
    taggedPhotos: [],
    startNumbersOfTaggedPhotos: [],
    autoRefresh: false,
    autoRefreshInterval: 10,
    taggedPhotosCurrentPage: 1,
    taggedPhotosTotalPages: null,
    taggedPhotosPageSize: 20,
    taggedPhotosCount: 0,
    selectedPhotoToTag: null,
    startNumbersOfEvent: []
  }

  loadUntaggedPhotos = async () => {
    const event = getSelectedAtpEvent()

    if (event) {
      this.setState({ loading: true })
      const { success, error, photos } = (
        await getUntaggedPhotosOfEvent(event.id)
      ).data

      if (success) {
        console.log('untagged photos', photos)
        this.setState({ loading: false, untaggedPhotos: photos })
      } else {
        this.setState({ loading: false, error, untaggedPhotos: [] })
      }
    }
  }

  loadTaggedPhotos = async () => {
    const event = getSelectedAtpEvent()

    if (event) {
      this.setState({ loading: true })
      const { taggedPhotosPageSize, taggedPhotosCurrentPage } = this.state

      const { success, error, photos, startNumbers } = (
        await getTaggedPhotosOfEvent(
          event.id,
          (taggedPhotosCurrentPage - 1) * taggedPhotosPageSize,
          taggedPhotosPageSize
        )
      ).data

      if (success) {
        const { taggedPhotosCount } = (
          await getTaggedPhotoCountOfEvent(event.id)
        ).data

        this.setState({
          loading: false,
          taggedPhotos: photos,
          taggedPhotosCount,
          startNumbersOfTaggedPhotos: startNumbers
        })
      } else {
        this.setState({
          loading: false,
          error,
          taggedPhotos: [],
          startNumbersOfTaggedPhotos: []
        })
      }
    }
  }

  loadStartNumbersOfEvent = async () => {
    this.setState({ loading: true, error: null })
    const event = getSelectedAtpEvent()

    if (event) {
      const { success, error, startNumbers } = (
        await getStartNumbersOfEvent(event.id)
      ).data
      if (success) {
        this.setState({ loading: false, startNumbersOfEvent: startNumbers })
      } else {
        this.setState({ loading: false, error })
      }
    } else {
      this.setState({ loading: false, error: 'Kein Event ausgewählt.' })
    }
  }

  handleChangeAutoRefresh = (enabled) => {
    if (enabled) {
      const { autoRefreshInterval } = this.state
      this.autoRefreshInterval = setInterval(() => {
        this.loadUntaggedPhotos()
        this.loadTaggedPhotos()
      }, autoRefreshInterval * 1000)
    } else if (this.autoRefreshInterval) {
      clearInterval(this.autoRefreshInterval)
    }

    this.setState({ autoRefresh: enabled })
  }

  handlePhotoToTagClick = (photo) => {
    this.setState({ selectedPhotoToTag: photo })
  }

  getRibbonColorForPhoto = (photo) => {
    if (photo.validated_by_user_id) {
      return 'green'
    }

    return 'red'
  }

  getRibbonContentForPhoto = (photo) => {
    if (photo.validated_by_user_id) {
      return (
        <>
          {photo.validated_by_user_id && <CheckOutlined />}
          {photo.synced_to_picthis && <CheckOutlined />}
        </>
      )
    }
    return <ExclamationOutlined />
  }

  componentDidMount = () => {
    this.unsubscribeFromNotifications = NotificationSubject.pipe(
      filter(
        (f) =>
          f.notificationName ===
          NotificationNames.userSelectedAtpEventNotification
      )
    ).subscribe(() => {
      // this.loadUntaggedPhotos();
      this.loadTaggedPhotos()
      this.loadStartNumbersOfEvent()
    })

    // this.loadUntaggedPhotos();
    this.loadTaggedPhotos()
    this.loadStartNumbersOfEvent()

    /*  const { autoRefresh, autoRefreshInterval } = this.state

    if (autoRefresh) {
      this.autoRefreshInterval = setInterval(() => {
        // this.loadUntaggedPhotos();
        this.loadTaggedPhotos()
      }, autoRefreshInterval * 1000)
    } */
  }

  componentWillUnmount = () => {
    this.unsubscribeFromNotifications.unsubscribe()
  }

  render() {
    const { loading, error } = this.state

    const event = getSelectedAtpEvent()

    return (
      <>
        <Typography.Title level={1}>Foto-Tagging</Typography.Title>
        {/* <Row style={{ margin: '12px 0' }}>
          <Checkbox
            checked={autoRefresh}
            onChange={(e) => {
              this.handleChangeAutoRefresh(e.target.checked)
            }}
            style={{ marginLeft: 'auto' }}
          >
            Auto-Refresh ({autoRefreshInterval} Sekunden)?
          </Checkbox>
          {autoRefresh && <Spin size="small" spinning />}
        </Row> */}
        {!event && (
          <Alert
            type="warning"
            showIcon
            message="Es wurde noch kein Event ausgewählt."
            style={{ margin: '0 0 12px 0' }}
          />
        )}
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '24px' }}
          />
        )}

        {event && (
          <Spin spinning={loading}>
            <TaggingModusNachStartnummer
              style={{ marginTop: '64px' }}
              startno={this.props.startno || null}
            />
          </Spin>
        )}
      </>
    )
  }
}

FotoTagging.propTypes = {
  startno: PropTypes.any
}

export default FotoTagging
