import { CheckOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Card,
  Col,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table
} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  getEventForCameraPosition,
  getPhotosInTimespan,
  getPhotosPaginated
} from '../../api/cameraPosition'
import { getCompleteParticipantListForEvent } from '../../api/participant'
import { tagPhotosOfCameraWithOffsets } from '../../api/photoTagging'
import { withRouter } from '../../withRouter'
import { getSubeventBewerbe } from '../../api/event'

class ZeitTaggingSettings extends Component {
  state = {
    loading: false,
    error: null,
    images: [],
    participantList: [],
    selectedParticipant: null,
    selectedTime: null,
    selectedSplitTimes: [],
    currentPage: 1,
    event: null,
    offsetMinus: 10,
    offsetPlus: 10,
    subEventBewerbe: [],
    selectedSubeventBewerbIds: []
  }

  loadEvent = async () => {
    const { params } = this.props
    const { cameraId } = params

    if (cameraId) {
      this.setState({ loading: true })
      const { success, error, event } = (
        await getEventForCameraPosition(cameraId)
      ).data

      if (success) {
        this.setState({ loading: false, event }, () => {
          this.loadImagesPaginated()
          this.loadParticipantListForEvent()
          this.loadSubeventBewerbe()
        })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  loadSubeventBewerbe = async () => {
    this.setState({ loading: true })
    const { event } = this.state

    const { success, error, bewerbe } = (await getSubeventBewerbe(event.id))
      .data

    if (success) {
      this.setState({ loading: false, subEventBewerbe: bewerbe })
    } else {
      this.setState({ loading: false, error })
    }
  }

  loadParticipantListForEvent = async () => {
    this.setState({ loading: true })
    const { event } = this.state

    const { success, error, participantList } = (
      await getCompleteParticipantListForEvent(event.id)
    ).data

    if (success) {
      this.setState({ loading: false, participantList })
    } else {
      this.setState({ loading: false, error })
    }
  }

  loadImagesPaginated = async () => {
    const { params } = this.props
    const { cameraId } = params

    if (cameraId) {
      this.setState({ loading: true })
      const { currentPage } = this.state

      const { success, error, photos } = (
        await getPhotosPaginated(cameraId, (currentPage - 1) * 25, 25)
      ).data

      if (success) {
        this.setState({ loading: false, images: photos })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  loadImagesForParticipant = async () => {
    const { selectedParticipant, selectedTime, offsetMinus, offsetPlus } =
      this.state
    const { params } = this.props
    const { cameraId } = params

    if (selectedParticipant) {
      this.setState({ loading: true })
      const time1 = moment(selectedTime, 'HH:mm:ss')
      const time2 = moment(selectedTime, 'HH:mm:ss')

      time1.add(offsetPlus, 'seconds')
      time2.subtract(offsetMinus, 'second')
      const { success, error, photos } = (
        await getPhotosInTimespan(
          cameraId,
          time2.format('HH:mm:ss'),
          time1.format('HH:mm:ss')
        )
      ).data

      if (success) {
        this.setState({ loading: false, images: photos })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  handleTagPhotos = async (replaceTags) => {
    const { params } = this.props
    const { cameraId } = params

    const {
      offsetMinus,
      offsetPlus,
      selectedSplitTimes,
      selectedSubeventBewerbIds
    } = this.state

    this.setState({ loading: true, error: null })

    const {
      success,
      error,
      message: m
    } = (
      await tagPhotosOfCameraWithOffsets(
        cameraId,
        offsetMinus,
        offsetPlus,
        selectedSplitTimes,
        replaceTags === true,
        selectedSubeventBewerbIds
      )
    ).data

    if (success) {
      this.setState({ loading: false })
      message.success(m)
    } else {
      this.setState({ loading: false, error })
    }
  }

  addTimes = (times) => {
    const startTime = times[0]
    times.splice(1).forEach((t) => {
      const seconds = moment.duration(t.format('HH:mm:ss')).as('seconds')
      startTime.add(seconds, 'seconds')
    })
    return startTime
  }

  getCustomDaytimesForParticipant = (participant) => {
    let daytimes = []
    for (let i = 1; i <= 20; i += 1) {
      if (participant[`custom_daytime${i}`]) {
        const customDaytime = participant[`custom_daytime${i}`]
        daytimes[i] = customDaytime
      } else {
        daytimes[i] = null
      }
    }

    return daytimes
  }

  componentDidMount = () => {
    this.loadEvent()
  }

  render() {
    const {
      loading,
      error,
      images,
      currentPage,
      participantList,
      selectedParticipant,
      offsetMinus,
      offsetPlus,
      selectedTime,
      subEventBewerbe,
      selectedSubeventBewerbIds
    } = this.state

    return (
      <>
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '24px' }}
          />
        )}
        <Spin spinning={loading}>
          <Row gutter={48}>
            <Col lg={16}>
              {images.length === 0 && (
                <Alert type="info" showIcon message="Keine Fotos gefunden." />
              )}
              {(images.length > 0 || currentPage > 1) && (
                <>
                  <Button
                    type="default"
                    disabled={currentPage === 1}
                    onClick={() => {
                      this.setState(
                        { currentPage: currentPage - 1 },
                        this.loadImagesPaginated
                      )
                    }}
                  >
                    zurück
                  </Button>{' '}
                  <Button
                    type="default"
                    onClick={() => {
                      this.setState(
                        { currentPage: currentPage + 1 },
                        this.loadImagesPaginated
                      )
                    }}
                    disabled={images.length === 0}
                  >
                    weiter
                  </Button>
                </>
              )}
              {images.length > 0 && (
                <Row gutter={[24, 24]}>
                  {images.map((i) => (
                    <Col md={12} xl={8} key={i.id}>
                      <div style={{ padding: '24px', textAlign: 'center' }}>
                        <a
                          href={i.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            key={i.id}
                            src={i.thumbnail_url}
                            title={i.new_filename}
                            alt={i.new_filename}
                            style={{
                              width: '100%',
                              height: 'auto',
                              objectFit: 'cover',
                              maxHeight: '240px'
                            }}
                          />
                        </a>
                        {moment(i.file_date_time).format('HH:mm:ss')}
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
            <Col lg={8}>
              {selectedParticipant && (
                <Card
                  title={`Startnummer ${selectedParticipant.start_no} ausgewählt`}
                  extra={
                    <>
                      <Button
                        type="primary"
                        icon={<CheckOutlined />}
                        size="small"
                        loading={loading}
                        disabled={offsetMinus == null || offsetPlus == null}
                        onClick={() => {
                          this.handleTagPhotos(false)
                        }}
                        style={{ marginRight: '6px' }}
                      >
                        Taggen
                      </Button>
                      <Popconfirm
                        title="Diese Zeiten übernehmen? Falls du diese Fotos schon einmal getaggt hast, werden diese gelöscht. Fortfahren?"
                        okText="Neu taggen"
                        onConfirm={() => {
                          this.handleTagPhotos(true)
                        }}
                      >
                        <Button
                          type="default"
                          icon={<CheckOutlined />}
                          size="small"
                          loading={loading}
                          disabled={offsetMinus == null || offsetPlus == null}
                        >
                          Neu taggen
                        </Button>
                      </Popconfirm>
                    </>
                  }
                >
                  <Row gutter={32} align="middle">
                    <Col
                      xs={24}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '24px',
                        alignItems: 'center'
                      }}
                    >
                      <div>
                        Offset Minus:{' '}
                        <InputNumber
                          value={offsetMinus}
                          onChange={(number) => {
                            this.setState(
                              { offsetMinus: number },
                              this.loadImagesForParticipant
                            )
                          }}
                        />
                      </div>
                      <div>
                        Offset Plus:{' '}
                        <InputNumber
                          value={offsetPlus}
                          onChange={(number) => {
                            this.setState(
                              { offsetPlus: number },
                              this.loadImagesForParticipant
                            )
                          }}
                        />
                      </div>
                      <div>
                        <span style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                          {selectedTime}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={32} align="middle">
                    <Col xs={24}>
                      <Select
                        mode="multiple"
                        placeholder="Bewerbe auswählen (optional) ..."
                        options={subEventBewerbe.map((se) => ({
                          value: se.id,
                          label: se.bewerb_name
                        }))}
                        style={{ width: '100%' }}
                        values={selectedSubeventBewerbIds}
                        onChange={(newIds) => {
                          this.setState({ selectedSubeventBewerbIds: newIds })
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              )}
              <Table
                rowKey="id"
                size="small"
                dataSource={participantList}
                columns={[
                  { title: 'Startnr.', dataIndex: 'start_no' },
                  { title: 'Startzeit', dataIndex: 'start_time' },
                  {
                    title: 'Uhrzeit Swim',
                    key: 'swimSplit',
                    render: (p) => {
                      if (p.start_time && p.swim_split_time) {
                        const startTime = moment(p.start_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const time = moment(p.swim_split_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const timesAdded = this.addTimes([startTime, time])
                        if (startTime.isValid() && time.isValid()) {
                          return (
                            <>
                              {timesAdded.format('HH:mm:ss')}{' '}
                              <Button
                                size="small"
                                type="link"
                                icon={<SearchOutlined />}
                                onClick={() => {
                                  this.setState(
                                    {
                                      selectedParticipant: p,
                                      selectedTime:
                                        timesAdded.format('HH:mm:ss'),
                                      selectedSplitTimes: ['swim_split_time']
                                    },
                                    this.loadImagesForParticipant
                                  )
                                }}
                              />
                            </>
                          )
                        }
                      }
                      return '-'
                    },
                    sorter: (p1, p2) => {
                      if (
                        p1.start_time &&
                        p1.swim_split_time &&
                        p2.start_time &&
                        p2.swim_split_time
                      ) {
                        const startTime1 = moment(p1.start_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const time1 = moment(p1.swim_split_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const startTime2 = moment(p2.start_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const time2 = moment(p2.swim_split_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])

                        const ttime1 = this.addTimes([startTime1, time1])
                        const ttime2 = this.addTimes([startTime2, time2])

                        return ttime1.unix() - ttime2.unix()
                      }

                      if (p1.start_time && p1.swim_split_time) {
                        return -1
                      }

                      return 1
                    }
                  },
                  /*  {
                    title: 'Uhrzeit Bike',
                    key: 'bikeSplit',
                    render: (p) => {
                      if (
                        p.start_time &&
                        p.swim_split_time &&
                        p.bike_split_time
                      ) {
                        const startTime = moment(p.start_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const time1 = moment(p.swim_split_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const time2 = moment(p.bike_split_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        if (
                          startTime.isValid() &&
                          time1.isValid() &&
                          time2.isValid()
                        ) {
                          return this.addTimes([startTime, time1, time2])
                        }
                      }
                      return '-'
                    }
                  },
                  {
                    title: 'Uhrzeit Run',
                    key: 'runSplit',
                    render: (p) => {
                      if (
                        p.start_time &&
                        p.swim_split_time &&
                        p.bike_split_time &&
                        p.run_split_time
                      ) {
                        const startTime = moment(p.start_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const time1 = moment(p.swim_split_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const time2 = moment(p.bike_split_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const time3 = moment(p.run_split_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        if (
                          startTime.isValid() &&
                          time1.isValid() &&
                          time2.isValid() &&
                          time3.isValid()
                        ) {
                          return this.addTimes([startTime, time1, time2, time3])
                        }
                      }
                      return '-'
                    }
                  }, */
                  {
                    title: 'Uhrzeit Finish',
                    key: 'gesamt',
                    render: (p) => {
                      if (p.start_time && p.run_time) {
                        const startTime = moment(p.start_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])

                        const time = moment(p.run_time, ['HH:mm:ss', 'H:mm:ss'])
                        const timesAdded = this.addTimes([startTime, time])

                        if (startTime.isValid() && time.isValid()) {
                          return (
                            <>
                              {timesAdded.format('HH:mm:ss')}{' '}
                              <Button
                                size="small"
                                type="link"
                                icon={<SearchOutlined />}
                                onClick={() => {
                                  this.setState(
                                    {
                                      selectedParticipant: p,
                                      selectedTime:
                                        timesAdded.format('HH:mm:ss'),
                                      selectedSplitTimes: ['run_time']
                                    },
                                    this.loadImagesForParticipant
                                  )
                                }}
                              />
                            </>
                          )
                        }
                      }
                      return '-'
                    },
                    sorter: (p1, p2) => {
                      if (
                        p1.start_time &&
                        p1.run_time &&
                        p2.start_time &&
                        p2.run_time
                      ) {
                        const startTime1 = moment(p1.start_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const time1 = moment(p1.run_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const startTime2 = moment(p2.start_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])
                        const time2 = moment(p2.run_time, [
                          'HH:mm:ss',
                          'H:mm:ss'
                        ])

                        const ttime1 = this.addTimes([startTime1, time1])
                        const ttime2 = this.addTimes([startTime2, time2])

                        return ttime1.unix() - ttime2.unix()
                      }

                      if (p1.start_time && p1.run_time) {
                        return -1
                      }

                      return 1
                    }
                  },
                  {
                    title: 'Custom DT',
                    key: 'custom-daytime',
                    render: (participant) => {
                      const daytimes =
                        this.getCustomDaytimesForParticipant(participant)
                      return (
                        <Select
                          size="small"
                          placeholder="Custom Daytimes"
                          onSelect={(_, option) => {
                            const { key: customDtIndex, value: daytime } =
                              option

                            this.setState(
                              {
                                selectedParticipant: participant,
                                selectedTime: daytime,
                                selectedSplitTimes: [
                                  `custom_daytime${customDtIndex}`
                                ]
                              },
                              this.loadImagesForParticipant
                            )
                          }}
                        >
                          {/* ACHTUNG: Index ist schon +1, weil er bei 1 beginnt */}
                          {daytimes.map((dt, index) => {
                            return (
                              <Select.Option key={index} value={dt}>
                                {dt || '-'}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      )
                    }
                  }
                ]}
                pagination={{
                  pageSize: '40',
                  position: ['topRight', 'bottomRight']
                }}
              />
            </Col>
          </Row>
        </Spin>
      </>
    )
  }
}

ZeitTaggingSettings.propTypes = {
  params: PropTypes.any
}

export default withRouter(ZeitTaggingSettings)
