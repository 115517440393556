import PropTypes from 'prop-types'
import { Alert, Form, Input, List, message, Modal, Row, Switch } from 'antd'
import React, { Component } from 'react'
import { getAllEvents } from '../../api/event'
import { getEventsWithAccess, updateUserAccessToEvents } from '../../api/user'

class EditUserModal extends Component {
  state = {
    loading: false,
    error: null,
    firstName: null,
    lastName: null,
    email: null,
    eventsWithAccess: [],
    allEvents: []
  }

  loadEventsWithAccess = async () => {
    const { user } = this.props
    this.setState({ loading: true })
    const { success, error, events } = (await getEventsWithAccess(user.id)).data

    if (success) {
      this.setState({ loading: false, eventsWithAccess: events })
    } else {
      this.setState({ loading: false, error })
    }
  }

  loadAllEvents = async () => {
    this.setState({ loading: true })
    const { success, error, events } = (await getAllEvents()).data

    if (success) {
      this.setState({ loading: false, allEvents: events })
    } else {
      this.setState({ loading: false, error })
    }
  }

  toggleAccessToEvent = async (eventId, hasAccess) => {
    this.setState({ loading: true, error: null })
    const { eventsWithAccess } = this.state
    const eventIds = eventsWithAccess
      .filter((e) => e.id !== eventId)
      .map((e) => e.id)
    const { user } = this.props
    const {
      success,
      error,
      message: m
    } = (
      await updateUserAccessToEvents(
        user.id,
        hasAccess ? [...eventIds, eventId] : eventIds || []
      )
    ).data

    if (success) {
      this.setState({ loading: false }, this.loadEventsWithAccess)
      message.success(m)
    } else {
      this.setState({ loading: false, error })
    }
  }

  componentDidMount = () => {
    const { user } = this.props

    if (user) {
      this.loadEventsWithAccess()
      this.loadAllEvents()
      this.setState({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { user } = this.props
    const { user: prevUser } = prevProps

    if (user && user !== prevUser) {
      this.loadEventsWithAccess()
      this.loadAllEvents()
      this.setState({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email
      })
    }
  }

  render() {
    const {
      loading,
      error,
      firstName,
      lastName,
      email,
      allEvents,
      eventsWithAccess
    } = this.state

    const { visible, onClose, user } = this.props
    return (
      <Modal
        visible={visible}
        onCancel={onClose}
        title="Benutzer bearbeiten"
        width={600}
        centered
        confirmLoading={loading}
        okText="Speichern"
      >
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '24px' }}
          />
        )}
        {user && (
          <Form wrapperCol={{ span: 15 }} labelCol={{ span: 9 }}>
            <Form.Item label="Benutzername">
              <Input disabled value={user.alias} />
            </Form.Item>
            <Form.Item label="Vorname">
              <Input
                value={firstName}
                onChange={(e) => {
                  this.setState({ firstName: e.target.value })
                }}
              />
            </Form.Item>
            <Form.Item label="Nachname">
              <Input
                value={lastName}
                onChange={(e) => {
                  this.setState({ lastName: e.target.value })
                }}
              />
            </Form.Item>
            <Form.Item label="E-Mail Adresse">
              <Input
                value={email}
                onChange={(e) => {
                  this.setState({ email: e.target.value })
                }}
              />
            </Form.Item>
            <Form.Item label="Zugriff zu Events">
              {Number(user.is_admin) === 1 ? (
                <span>Admin hat auf alle Events Zugriff</span>
              ) : (
                <>
                  <List
                    rowKey="id"
                    size="small"
                    bordered
                    dataSource={allEvents.reduce(
                      (subevents, event) =>
                        event.subEvents
                          ? [
                              ...subevents,
                              ...event.subEvents.map((se) => ({
                                ...se,
                                parent_event_name: event.event_name
                              }))
                            ]
                          : subevents,
                      []
                    )}
                    renderItem={(event) => (
                      <List.Item key={event.id}>
                        <Row
                          justify="space-between"
                          align="middle"
                          style={{ width: '100%' }}
                        >
                          <span>
                            {event.parent_event_name} - {event.event_name}
                          </span>
                          <Switch
                            checked={
                              eventsWithAccess.findIndex(
                                (e) => e.id === event.id
                              ) >= 0
                            }
                            onChange={(checked) => {
                              this.toggleAccessToEvent(event.id, checked)
                            }}
                          />
                        </Row>
                      </List.Item>
                    )}
                    locale={{ emptyText: 'Zu keinem Event Zugriff.' }}
                  />
                </>
              )}
            </Form.Item>
          </Form>
        )}
      </Modal>
    )
  }
}

EditUserModal.propTypes = {
  onClose: PropTypes.any,
  user: PropTypes.shape({
    alias: PropTypes.any,
    email: PropTypes.any,
    first_name: PropTypes.any,
    id: PropTypes.any,
    is_admin: PropTypes.any,
    last_name: PropTypes.any
  }),
  visible: PropTypes.any
}

export default EditUserModal
