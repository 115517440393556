import { LogoutOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  ConfigProvider,
  Layout,
  message,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch
} from 'antd'
import deDE from 'antd/es/locale/de_DE'
import moment from 'moment'
import 'moment/locale/de'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withCookies } from 'react-cookie'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { checkIfUserIsLoggedIn, logout } from './api/api'
import { getAllEvents } from './api/event'
import './App.less'
import CloudTagging from './components/CloudTagging'
import Dashboard from './components/Dashboard'
import Einstellungen from './components/Einstellungen'
import Events from './components/Events'
import FotosDurchsehen from './components/FotosDurchsehen'
import FotoTagging from './components/FotoTagging'
import Login from './components/Login'
import Navigation from './components/Navigation'
import Teilnehmer from './components/Teilnehmer'
import Users from './components/Users'
import ZeitTaggingSettings from './components/ZeitTaggingSettings'
import picthisWebSocket from './liveMode'
import { setSelectedAtpEvent } from './utils/AtpEventManagement'
import UserManagement from './utils/UserManagement'

moment.locale('de')

class App extends Component {
  state = {
    validatingLoggedInUser: true,
    userIsLoggedIn: false,
    selectedEvent: null,
    atpEvents: [],
    liveModeEnabled: false
  }

  constructor(props) {
    super(props)

    const { cookies } = props
    if (cookies) {
      this.state.sid = cookies.get('sid') || null
    }

    picthisWebSocket.connect(this.websocketReceivedMsg).then(() => {
      picthisWebSocket.sendMessage({ cmd: 'status' })
    })

    setInterval(() => {
      picthisWebSocket.sendMessage({ cmd: 'status' })
    }, 3000)
  }

  checkUserLoggedIn = async (callback) => {
    let { sid } = this.state

    // versuchen, die sid noch aus den cookies zu bekommen
    if (!sid) {
      const { cookies } = this.props
      if (cookies) {
        const cookieSid = cookies.get('sid') || null
        this.setState({ sid: cookieSid })
        sid = cookieSid
      }
    }

    if (sid) {
      // validate logged in user
      this.setState({ validatingLoggedInUser: true })
      // session id validieren
      try {
        const { loggedIn, user } = (await checkIfUserIsLoggedIn()).data
        if (loggedIn) {
          UserManagement.setLoggedInUser(user)
          this.setState(
            {
              userIsLoggedIn: true,
              validatingLoggedInUser: false
            },
            () => {
              if (callback) callback(true)
            }
          )
        } else {
          this.setState(
            {
              userIsLoggedIn: false,
              validatingLoggedInUser: false
            },
            () => {
              if (callback) callback(false)
            }
          )
        }
      } catch (error) {
        this.setState(
          {
            userIsLoggedIn: false,
            validatingLoggedInUser: false
          },
          () => {
            if (callback) callback(false)
          }
        )
      }
    } else {
      this.setState(
        {
          userIsLoggedIn: false,
          validatingLoggedInUser: false
        },
        () => {
          if (callback) callback(false)
        }
      )
    }
  }

  /*
  checkCurrentVersion = async () => {
    const { version } = (await getCurrentRazeVersion()).data;
    const compare = RazeFunctions.versionCompare(version, razeVersion);

    if (compare > 0) {
      this.setState({ deprecatedRazeVersion: true });
    } else {
      this.setState({ deprecatedRazeVersion: false });
    }
  };
  */

  websocketReceivedMsg = (msg) => {
    try {
      const json = JSON.parse(msg)
      if (json.cmd) {
        switch (json.cmd) {
          case 'status':
            this.setState({ liveModeEnabled: json.running || false })
            if (json.running) picthisWebSocket.sendMessage({ cmd: 'progress' })
            break
          case 'import':
            if (json.remaining && Number(json.remaining) > 0) {
              this.hideInfo = message.loading({
                content: `${json.remaining} Fotos werden noch importiert ...`,
                key: 'remainingFotosMessage',
                duration: 0
              })
            }
            if (Number(json.remaining) === 0 && this.hideInfo) this.hideInfo()
            break
          default:
            break
        }
      }
    } catch {
      // nix
    }
  }

  toggleLiveMode = () => {
    const { liveModeEnabled } = this.state
    if (liveModeEnabled) picthisWebSocket.sendMessage({ cmd: 'stop' })
    else {
      picthisWebSocket.sendMessage({ cmd: 'start' })
      picthisWebSocket.sendMessage({ cmd: 'progress' })
    }

    this.setState({ liveModeEnabled: true })
  }

  userLoggedInSuccessfully = () => {
    this.setState({ userIsLoggedIn: true, validatingLoggedInUser: false })
  }

  handleLogout = async () => {
    const { cookies } = this.props
    const { success } = (await logout()).data
    if (success && success === 1) {
      if (cookies) {
        this.setState({ userIsLoggedIn: false }, () => {
          cookies.remove('sid')
        })
      }
    } else {
      message.error('Fehler beim Abmelden.')
    }
  }

  loadAllAtpEvents = async () => {
    const { success, error, events } = (await getAllEvents()).data
    if (success) {
      console.log('events', events)
      this.setState({ atpEvents: events })
    } else {
      message.error(error)
    }
  }

  handleAtpEventSelected = (eventId) => {
    const { atpEvents } = this.state
    const event = atpEvents
      .find(
        (atpe) =>
          atpe.subEvents &&
          atpe.subEvents.findIndex((se) => Number(se.id) === Number(eventId)) >=
            0
      )
      .subEvents.find((se) => Number(se.id) === Number(eventId))
    this.setState({ selectedEvent: event })
    setSelectedAtpEvent(event)
    sessionStorage.setItem('selectedAtpEvent', JSON.stringify(event))
    window.location.reload()
  }

  componentDidMount = () => {
    // checken ob dark mode oder nicht
    // this.setState({ darkModeEnabled: darkModeEnabled() })

    if (sessionStorage.getItem('selectedAtpEvent')) {
      this.setState({
        selectedEvent: JSON.parse(sessionStorage.getItem('selectedAtpEvent'))
      })
      setSelectedAtpEvent(
        JSON.parse(sessionStorage.getItem('selectedAtpEvent'))
      )
    }

    this.checkUserLoggedIn((loggedIn) => {
      if (loggedIn) {
        this.loadAllAtpEvents()
      }
    })
  }

  render() {
    const {
      validatingLoggedInUser,
      userIsLoggedIn,
      atpEvents,
      selectedEvent,
      liveModeEnabled
    } = this.state

    if (validatingLoggedInUser) {
      return (
        <Row
          style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffffff'
          }}
        >
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Spin spinning size="large" />
            <p
              style={{
                margin: '10px 0',
                color: '#444444'
              }}
            >
              Deine Sitzung wird geladen ...
            </p>
          </Col>
        </Row>
      )
    }

    if (!userIsLoggedIn) {
      return <Login onSuccess={this.userLoggedInSuccessfully} />
    }

    return (
      <ConfigProvider locale={deDE}>
        <BrowserRouter basename="/">
          <Layout className="layout">
            <Layout.Header style={{ display: 'flex' }}>
              <div className="logo" />

              <Navigation path="/*" />
              <div style={{ marginLeft: 'auto', display: 'inline-block' }}>
                {UserManagement.isAdmin() && (
                  <div
                    style={{
                      display: 'inline-block',
                      color: 'white',
                      marginRight: '24px'
                    }}
                  >
                    <Popconfirm
                      title="Live-Modus"
                      onConfirm={this.toggleLiveMode}
                      okText={liveModeEnabled ? 'Stoppen' : 'Starten'}
                      trigger="click"
                    >
                      <Switch checked={liveModeEnabled} />
                      Live-Modus
                    </Popconfirm>
                  </div>
                )}
                <Select
                  size="small"
                  value={selectedEvent ? selectedEvent.id : undefined}
                  onChange={this.handleAtpEventSelected}
                  style={{ width: '340px', marginRight: '12px' }}
                  placeholder="Event auswählen ..."
                >
                  {atpEvents.map((ev) => (
                    <Select.OptGroup key={ev.id} label={ev.event_name}>
                      {ev.subEvents &&
                        ev.subEvents.map((subev) => (
                          <Select.Option key={subev.id} value={subev.id}>
                            {subev.event_name}
                          </Select.Option>
                        ))}
                    </Select.OptGroup>
                  ))}
                </Select>
                <Button
                  icon={<LogoutOutlined />}
                  size="small"
                  danger
                  type="primary"
                  onClick={this.handleLogout}
                >
                  Logout
                </Button>
              </div>
            </Layout.Header>
            <Layout.Content style={{ padding: '50px 50px' }}>
              <Routes>
                <Route element={<Dashboard />} path="/" />
                <Route
                  element={<FotoTagging />}
                  path="/photo-tagging/:startno"
                />
                <Route element={<FotoTagging />} path="/photo-tagging/" />
                <Route element={<Teilnehmer />} path="/participants/*" />
                <Route element={<Einstellungen />} path="/settings/*" />
                <Route element={<Events />} path="/events/*" />
                <Route element={<Users />} path="/users/*" />
                <Route
                  element={<ZeitTaggingSettings />}
                  path="/zeit-tagging-settings/:cameraId"
                />
                <Route
                  element={<CloudTagging />}
                  path="/cloud-tagging/:cameraId"
                />
                <Route
                  element={<FotosDurchsehen />}
                  path="/fotos-durchsehen/:cameraId"
                />
              </Routes>
            </Layout.Content>
          </Layout>
        </BrowserRouter>
      </ConfigProvider>
    )
  }
}

App.propTypes = {
  cookies: PropTypes.shape({
    get: PropTypes.func,
    remove: PropTypes.func
  })
}

export default withCookies(App)
