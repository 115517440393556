import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Typography,
  Spin,
  Alert,
  Row,
  message,
  Button,
  Drawer,
  Popconfirm,
  Input
} from 'antd'
import {
  LeftOutlined,
  RightOutlined,
  CheckOutlined,
  DeleteOutlined,
  SearchOutlined
} from '@ant-design/icons'
import { redirect } from 'react-router-dom'
import { getSelectedAtpEvent } from '../../utils/AtpEventManagement'
import {
  getTaggedPhotosOfStartNo,
  deleteTaggedPhotosOfStartNoAndCameraPosition,
  setTaggedPhotosValidatedByUser
} from '../../api/photoTagging'
import WeitereFotosVonKameraZuZeitpunktModal from './WeitereFotosVonKameraZuZeitpunktModal'
import { cameraPositionsForEvent as getCameraPositionsForEvent } from '../../api/cameraPosition'
import PreviewImage from './PreviewImage'

class TaggingModusNachStartnummer extends Component {
  state = {
    loading: false,
    error: null,
    taggedPhotos: [],
    startNo: null,
    allStartNumbers: [],
    selectedPhotos: [],
    getPhotosBeforeAndAfterPhoto: null,
    getPhotosOfCamera: null,
    cameraPositionsForEvent: []
  }

  loadCameraPositionsForEvent = async () => {
    const event = getSelectedAtpEvent()

    if (event) {
      this.setState({ loading: true })
      const { success, error, cameraPositions } = (
        await getCameraPositionsForEvent(event.id)
      ).data

      if (success) {
        this.setState({
          loading: false,
          cameraPositionsForEvent: cameraPositions
        })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  loadPhotosOfStartNo = async () => {
    const event = getSelectedAtpEvent()

    if (event) {
      this.setState({ loading: true, error: null })
      const { startNo } = this.state

      const { success, error, taggedPhotos, allStartNumbers } = (
        await getTaggedPhotosOfStartNo(startNo, event.id)
      ).data

      if (success) {
        this.setState({
          loading: false,
          taggedPhotos,
          allStartNumbers,
          startNo: !startNo ? allStartNumbers[0] : startNo
        })
      } else {
        this.setState({ loading: false, error })
      }
    } else {
      message.warn('Kein Event ausgewählt.')
    }
  }

  componentDidMount = () => {
    this.loadCameraPositionsForEvent()

    const { startno } = this.props

    this.setState({ startNo: startno }, () => {
      this.loadPhotosOfStartNo()
    })

    if (startno) redirect(`/photo-tagging/${startno}`)
  }

  handlePrevStartNoClick = () => {
    const { allStartNumbers, startNo } = this.state
    const index = allStartNumbers.findIndex((asn) => asn === startNo)

    if (index > 0) {
      this.setState(
        { startNo: allStartNumbers[index - 1], selectedPhotos: [] },
        () => {
          this.loadPhotosOfStartNo()
          redirect(`/photo-tagging/${allStartNumbers[index - 1]}`)
        }
      )
    }
  }

  handleNextStartNoClick = () => {
    const { allStartNumbers, startNo } = this.state
    const index = allStartNumbers.findIndex((asn) => asn === startNo)

    if (index < allStartNumbers.length - 1) {
      this.setState(
        { startNo: allStartNumbers[index + 1], selectedPhotos: [] },
        () => {
          this.loadPhotosOfStartNo()
          redirect(`/photo-tagging/${allStartNumbers[index + 1]}`)
        }
      )
    }
  }

  handlePhotoClick = (photo) => {
    const { selectedPhotos } = this.state

    const index = selectedPhotos.findIndex((sp) => sp.id === photo.id)

    if (index >= 0) {
      this.setState({
        selectedPhotos: selectedPhotos.filter((sp) => sp.id !== photo.id)
      })
    } else {
      this.setState({ selectedPhotos: [...selectedPhotos, photo] })
    }
  }

  handleDeleteSelectedPhotos = async () => {
    this.setState({ loading: true, error: null })
    const { selectedPhotos, startNo } = this.state

    if (selectedPhotos.length > 0) {
      const cameras = selectedPhotos.reduce((c, curr) => {
        if (
          c.findIndex(
            (c2) => c2.camera_position_id === curr.camera.camera_position_id
          ) >= 0
        ) {
          return c
        }

        return [...c, curr.camera]
      }, [])

      let count = 0
      cameras.forEach(async (c) => {
        const {
          success,
          error,
          message: m
        } = (
          await deleteTaggedPhotosOfStartNoAndCameraPosition(
            startNo,
            c.camera_position_id,
            selectedPhotos.filter(
              (sp) => sp.camera.camera_position_id === c.camera_position_id
            )
          )
        ).data

        if (success) {
          message.success(m)
        } else {
          message.error(error)
        }

        if (count === cameras.length - 1) {
          this.setState(
            { loading: false, selectedPhotos: [] },
            this.loadPhotosOfStartNo
          )
        }

        count += 1
      })
    } else {
      this.setState({ loading: false, error: 'Keine Fotos ausgewählt.' })
    }
  }

  handleContextMenuClick = (photo, { key, domEvent }) => {
    domEvent.preventDefault()
    domEvent.stopPropagation()
    if (key === 'getPhotosFromThatTime') {
      this.setState({
        getPhotosBeforeAndAfterPhoto: photo
      })
    } else if (key === 'showPhotoInNewTab') {
      window.open(photo.photo.photo_url, '_blank')
    }
  }

  handleAcceptSelectedPhotos = async () => {
    this.setState({ loading: true, error: null })
    const { selectedPhotos } = this.state
    const photoIds = selectedPhotos.map((sp) => sp.id)

    const {
      success,
      error,
      message: m
    } = (await setTaggedPhotosValidatedByUser(photoIds)).data

    if (success) {
      this.setState(
        { loading: false, selectedPhotos: [] },
        this.loadPhotosOfStartNo
      )
      message.success(m)
    } else {
      this.setState({ loading: false, error })
      message.error(error)
    }
  }

  handleAcceptAllPhotos = async () => {
    this.setState({ loading: true, error: null })
    const { taggedPhotos } = this.state
    const photoIds = taggedPhotos.map((tp) => tp.id)

    const {
      success,
      error,
      message: m
    } = (await setTaggedPhotosValidatedByUser(photoIds)).data

    if (success) {
      this.setState(
        { loading: false, selectedPhotos: [] },
        this.loadPhotosOfStartNo
      )
      message.success(m)
    } else {
      this.setState({ loading: false, error })
      message.error(error)
    }
  }

  invertSelectedPhotosFromCamera = (camera) => {
    const { taggedPhotos, selectedPhotos } = this.state
    const photosOfCamera = taggedPhotos.filter(
      (tp) => tp.camera.camera_position_id === camera.id
    )

    const diff = photosOfCamera.filter(
      (poc) => selectedPhotos.findIndex((sp) => sp.id === poc.id) === -1
    )

    let newSelectedPhotos = selectedPhotos.filter(
      (sp) => sp.camera.camera_position_id !== camera.id
    )

    if (newSelectedPhotos.length > 0 && diff.length > 0) {
      newSelectedPhotos = [...newSelectedPhotos, ...diff]
    } else if (diff.length > 0) {
      newSelectedPhotos = diff
    } else {
      newSelectedPhotos = []
    }

    this.setState({
      selectedPhotos: newSelectedPhotos
    })
  }

  render() {
    const {
      loading,
      error,
      taggedPhotos,
      startNo,
      selectedPhotos,
      getPhotosBeforeAndAfterPhoto,
      getPhotosOfCamera,
      cameraPositionsForEvent
    } = this.state
    const { style } = this.props

    return (
      <div style={style}>
        {startNo && (
          <div
            style={{
              display: 'block',
              position: 'absolute',
              top: -120,
              right: 0,
              height: '100%'
            }}
          >
            <div
              style={{
                fontSize: '1.2rem',
                position: 'sticky',
                top: 24,
                right: 0,
                backgroundColor: '#ffffff',
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                padding: '12px 24px',
                borderRadius: '8px',
                zIndex: 999
              }}
            >
              Startnummer #{startNo}
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '24px'
          }}
        >
          <Button
            icon={<LeftOutlined />}
            type="primary"
            onClick={this.handlePrevStartNoClick}
          >
            Vorige Startnummer
          </Button>
          <Button
            icon={<RightOutlined />}
            type="primary"
            onClick={this.handleNextStartNoClick}
          >
            Nächste Startnummer
          </Button>
        </div>
        <Typography.Title level={3}>
          <small>Startnummer #</small>{' '}
          <Input
            value={startNo}
            onChange={(e) => {
              this.setState({ startNo: e.target.value })
            }}
            onKeyDown={(e) => {
              // wenn Enter gedrückt wird
              if (e.which === 13 || e.keyCode === 13) {
                this.loadPhotosOfStartNo()
              }
            }}
            style={{ width: 'auto' }}
          />
          <Button
            onClick={this.loadPhotosOfStartNo}
            icon={<SearchOutlined />}
            shape="circle"
            style={{ marginLeft: '6px' }}
          />
        </Typography.Title>
        <Spin spinning={loading}>
          {error && (
            <Alert
              type="error"
              showIcon
              message={error}
              style={{ marginBottom: '24px' }}
            />
          )}
          {cameraPositionsForEvent.map((c) => {
            const photosOfCamera = taggedPhotos.filter(
              (tp) => tp.camera.camera_position_id === c.id
            )

            return (
              <Row
                style={{
                  marginBottom: '64px',
                  marginTop: '24px',
                  display: 'block'
                }}
                key={c.id}
              >
                <Row style={{ display: 'block' }}>
                  <Typography.Title level={5}>
                    Kamera #{c.position_number}{' '}
                    <small className="text-secondary">
                      {c.position_description}
                    </small>
                    <Button
                      type="link"
                      style={{ marginLeft: '12px', padding: 0 }}
                      onClick={() => {
                        this.setState({ getPhotosOfCamera: c })
                      }}
                    >
                      Fotos suchen
                    </Button>
                    <Button
                      type="link"
                      style={{ marginLeft: '24px', padding: 0 }}
                      onClick={() => {
                        this.invertSelectedPhotosFromCamera(c)
                      }}
                    >
                      Auswahl umkehren
                    </Button>
                  </Typography.Title>
                </Row>
                <Row className="tagged-photos-preview">
                  {photosOfCamera.map((tp) => (
                    <PreviewImage
                      key={tp.id}
                      onClick={() => {
                        this.handlePhotoClick(tp)
                      }}
                      selected={
                        selectedPhotos.findIndex((sp) => sp.id === tp.id) >= 0
                      }
                      photo={tp}
                      onContextMenuClick={this.handleContextMenuClick}
                    />
                  ))}
                </Row>
              </Row>
            )
          })}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Button
              icon={<LeftOutlined />}
              type="primary"
              onClick={this.handlePrevStartNoClick}
            >
              Vorige Startnummer
            </Button>
            <Button type="primary" ghost onClick={this.handleAcceptAllPhotos}>
              Alle Fotos speichern
            </Button>
            <Button
              icon={<RightOutlined />}
              type="primary"
              onClick={this.handleNextStartNoClick}
            >
              Nächste Startnummer
            </Button>
          </div>
        </Spin>
        <Drawer
          visible={selectedPhotos.length > 0}
          placement="bottom"
          height="80px"
          maskClosable={false}
          closable={false}
          mask={false}
          bodyStyle={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Button
            type="primary"
            icon={<CheckOutlined />}
            onClick={this.handleAcceptSelectedPhotos}
          >
            Ausgewählte Fotos zu dieser Startnummer fixieren
          </Button>
          <Button
            type="link"
            style={{ margin: 0, padding: 0 }}
            onClick={() => {
              this.setState({ selectedPhotos: [] })
            }}
          >
            Auswahl aufheben ({selectedPhotos.length} Fotos ausgewählt)
          </Button>
          <Popconfirm
            title="Fotos wirklich löschen?"
            onConfirm={this.handleDeleteSelectedPhotos}
            okText="Ja, löschen"
            okButtonProps={{ danger: true }}
            cancelButtonProps="Nein"
          >
            <Button type="default" danger icon={<DeleteOutlined />}>
              Ausgewählte Fotos von dieser Startnummer entfernen
            </Button>
          </Popconfirm>
        </Drawer>
        <WeitereFotosVonKameraZuZeitpunktModal
          startNo={startNo}
          visible={
            getPhotosBeforeAndAfterPhoto != null || getPhotosOfCamera != null
          }
          originPhoto={getPhotosBeforeAndAfterPhoto}
          onCancel={() => {
            this.setState({
              getPhotosBeforeAndAfterPhoto: null,
              getPhotosOfCamera: null
            })
          }}
          camera={getPhotosOfCamera}
          onSuccess={() => {
            this.setState(
              { getPhotosBeforeAndAfterPhoto: null, getPhotosOfCamera: null },
              this.loadPhotosOfStartNo
            )
          }}
        />
      </div>
    )
  }
}

TaggingModusNachStartnummer.propTypes = {
  startno: PropTypes.any,
  style: PropTypes.any
}

export default TaggingModusNachStartnummer
