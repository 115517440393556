import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SaveOutlined,
  SearchOutlined
} from '@ant-design/icons'
import { Alert, Button, DatePicker, Row, Spin, message } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getPhotosOfTimeAndCamera } from '../../api/photoImport'
import { updateTaggedPhotosOfStartNoAndCameraPosition } from '../../api/photoTagging'
import { getSelectedAtpEvent } from '../../utils/AtpEventManagement'
import PreviewImage from './PreviewImage'

class WeitereFotosVonKameraZuZeitpunktModal extends Component {
  state = {
    loading: false,
    error: null,
    photosBeforeTime: [],
    photosAfterTime: [],
    selectedPhotos: [],
    manualSearchTime: null
  }

  loadPhotos = async () => {
    const { originPhoto, camera } = this.props
    const { manualSearchTime } = this.state

    if (manualSearchTime) {
      this.setState({
        loading: true,
        error: null,
        selectedPhotos: []
      })

      const event = getSelectedAtpEvent()

      const searchTimeString = `${moment(event.event_start_date).format(
        'YYYY-MM-DD'
      )} ${manualSearchTime.format('HH:mm:ss')}`

      const { success, error, photosBefore, photosAfter } = (
        await getPhotosOfTimeAndCamera(
          searchTimeString,
          camera ? camera.id : originPhoto.camera.camera_position_id
        )
      ).data

      if (success) {
        this.setState({
          loading: false,
          photosBeforeTime:
            originPhoto != null
              ? photosBefore.filter((p) => p.id !== originPhoto.photo_import_id)
              : photosBefore,
          photosAfterTime:
            originPhoto != null
              ? photosAfter.filter((p) => p.id !== originPhoto.photo_import_id)
              : photosAfter
        })
      } else {
        this.setState({ loading: false, error })
      }
    } else if (originPhoto && typeof originPhoto === 'object') {
      this.setState({
        loading: true,
        error: null,
        selectedPhotos: []
      })

      const { success, error, photosBefore, photosAfter } = (
        await getPhotosOfTimeAndCamera(
          originPhoto.photo.file_date_time,
          originPhoto.camera.camera_position_id
        )
      ).data

      if (success) {
        this.setState({
          loading: false,
          photosBeforeTime:
            originPhoto != null
              ? photosBefore.filter((p) => p.id !== originPhoto.photo_import_id)
              : photosBefore,
          photosAfterTime:
            originPhoto != null
              ? photosAfter.filter((p) => p.id !== originPhoto.photo_import_id)
              : photosAfter
        })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  handlePhotoClick = (photo) => {
    const { selectedPhotos } = this.state

    const index = selectedPhotos.findIndex((sp) => sp.id === photo.id)

    if (index >= 0) {
      this.setState({
        selectedPhotos: selectedPhotos.filter((sp) => sp.id !== photo.id)
      })
    } else {
      this.setState({ selectedPhotos: [...selectedPhotos, photo] })
    }
  }

  saveSelectedPhotos = async () => {
    this.setState({ loading: true, error: null })
    const { startNo, originPhoto, onSuccess, camera } = this.props
    const { selectedPhotos } = this.state

    const {
      success,
      error,
      message: m
    } = (
      await updateTaggedPhotosOfStartNoAndCameraPosition(
        startNo,
        camera ? camera.id : originPhoto.camera.camera_position_id,
        selectedPhotos.map((sp) => sp.photo_import_id || sp.id)
      )
    ).data

    if (success) {
      message.success(m)
      this.setState({ loading: false }, onSuccess)
    } else {
      this.setState({ loading: false, error })
    }
  }

  handleContextMenuClick = (photo, { key, domEvent }) => {
    domEvent.preventDefault()
    domEvent.stopPropagation()
    if (key === 'showPhotoInNewTab') {
      window.open(photo.photo.photo_url, '_blank')
    }
  }

  handleLeftClick = () => {
    const { photosBeforeTime } = this.state
    if (photosBeforeTime && photosBeforeTime.length > 1) {
      this.setState(
        {
          manualSearchTime: moment(photosBeforeTime[1].file_date_time)
        },
        this.loadPhotos
      )
    }
  }

  handleRightClick = () => {
    const { photosAfterTime } = this.state
    if (photosAfterTime && photosAfterTime.length > 1) {
      this.setState(
        {
          manualSearchTime: moment(
            photosAfterTime[photosAfterTime.length - 2].file_date_time
          )
        },
        this.loadPhotos
      )
    }
  }

  componentDidUpdate = ({
    originPhoto: prevOriginPhoto,
    visible: prevVisible
  }) => {
    const { originPhoto, visible } = this.props

    if (originPhoto && originPhoto !== prevOriginPhoto) {
      this.loadPhotos()
    }

    if (prevVisible && !visible) {
      // das modal wurde ausgeblendet
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        photosBeforeTime: [],
        photosAfterTime: [],
        selectedPhotos: [],
        manualSearchTime: null
      })
    }
  }

  render() {
    const { visible, onCancel, originPhoto, camera } = this.props
    const {
      loading,
      error,
      photosBeforeTime,
      photosAfterTime,
      selectedPhotos,
      manualSearchTime
    } = this.state

    let modalTitle = 'Fotos von Kamera #'
    if (camera) {
      modalTitle = `${modalTitle} ${camera.position_number} hinzufügen`
    } else if (originPhoto) {
      if (originPhoto.camera) {
        modalTitle = `${modalTitle} ${originPhoto.camera.position_number} hinzufügen`
      }
    }

    return (
      <Modal
        open={visible}
        width="95%"
        closable
        maskClosable={false}
        title={modalTitle}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="primary"
              disabled={selectedPhotos.length === 0}
              loading={loading}
              icon={<SaveOutlined />}
              onClick={this.saveSelectedPhotos}
            >
              Auswahl speichern
            </Button>
          </>
        }
      >
        <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Button
            shape="circle"
            icon={<ArrowLeftOutlined />}
            onClick={this.handleLeftClick}
          />
          <Button
            shape="circle"
            icon={<ArrowRightOutlined />}
            onClick={this.handleRightClick}
          />
        </Row>
        <Row style={{ alignItems: 'center' }}>
          Zeitpunkt der Fotos:
          <DatePicker.TimePicker
            value={manualSearchTime}
            onChange={(time) => {
              this.setState({ manualSearchTime: time })
            }}
            size="middle"
            format="HH:mm:ss"
          />
          <Button
            shape="circle"
            icon={<SearchOutlined />}
            onClick={this.loadPhotos}
            style={{ marginLeft: '6px' }}
          />
        </Row>
        <Spin spinning={loading}>
          {error && (
            <Alert
              type="error"
              showIcon
              message={error}
              style={{ marginBottom: '24px' }}
            />
          )}
          <Row className="tagged-photos-preview">
            {photosBeforeTime.map((p) => (
              <PreviewImage
                key={p.id}
                onClick={() => {
                  this.handlePhotoClick(p)
                }}
                selected={selectedPhotos.findIndex((sp) => sp.id === p.id) >= 0}
                onContextMenuClick={this.handleContextMenuClick}
                photo={p}
              />
            ))}
            {originPhoto && typeof originPhoto === 'object' && (
              <PreviewImage
                key={originPhoto.id}
                onClick={() => {
                  this.handlePhotoClick(originPhoto)
                }}
                selected={
                  selectedPhotos.findIndex((sp) => sp.id === originPhoto.id) >=
                  0
                }
                onContextMenuClick={this.handleContextMenuClick}
                photo={originPhoto}
              />
            )}
            {photosAfterTime.map((p) => (
              <PreviewImage
                key={p.id}
                onClick={() => {
                  this.handlePhotoClick(p)
                }}
                selected={selectedPhotos.findIndex((sp) => sp.id === p.id) >= 0}
                onContextMenuClick={this.handleContextMenuClick}
                photo={p}
              />
            ))}
          </Row>
        </Spin>
      </Modal>
    )
  }
}

WeitereFotosVonKameraZuZeitpunktModal.propTypes = {
  camera: PropTypes.shape({
    id: PropTypes.any,
    position_number: PropTypes.any
  }),
  onCancel: PropTypes.any,
  onSuccess: PropTypes.any,
  originPhoto: PropTypes.shape({
    camera: PropTypes.shape({
      camera_position_id: PropTypes.any,
      position_number: PropTypes.any
    }),
    id: PropTypes.any,
    photo: PropTypes.shape({
      file_date_time: PropTypes.any
    }),
    photo_import_id: PropTypes.any
  }),
  startNo: PropTypes.any,
  visible: PropTypes.any
}

export default WeitereFotosVonKameraZuZeitpunktModal
