import PropTypes from 'prop-types'
import React from 'react'
import { Popover, Dropdown, Menu, Badge } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

const getContextMenuForPhoto = (photo, onContextMenuClick) => (
  <Menu
    onClick={(props) => {
      onContextMenuClick(photo, props)
    }}
  >
    <Menu.Item key="getPhotosFromThatTime">
      Weitere Fotos zu diesem Zeitpunkt
    </Menu.Item>
    <Menu.Item key="showPhotoInNewTab">Foto groß anzeigen</Menu.Item>
  </Menu>
)

const PreviewImage = ({ photo, onClick, selected, onContextMenuClick }) => {
  const image = (
    <img
      src={photo.photo ? photo.photo.thumbnail_url : photo.thumbnail_url}
      alt={photo.photo ? photo.photo.file_date_time : photo.file_date_time}
      title={photo.photo ? photo.photo.file_date_time : photo.file_date_time}
      onClick={onClick}
      onKeyPress={onClick}
    />
  )

  if (photo.validated_by_user_id) {
    return (
      <div className={`img-preview-container ${selected ? 'selected' : ''}`}>
        <Badge.Ribbon color="#5bb564" text={<CheckOutlined />}>
          <Popover
            content={
              <img
                src={
                  photo.photo ? photo.photo.thumbnail_url : photo.thumbnail_url
                }
                alt={
                  photo.photo
                    ? photo.photo.file_date_time
                    : photo.file_date_time
                }
                title={
                  photo.photo
                    ? photo.photo.file_date_time
                    : photo.file_date_time
                }
                style={{ height: '80vh', width: 'auto' }}
              />
            }
            placement="left"
            mouseEnterDelay={0.8}
          >
            <Dropdown
              trigger={['contextMenu']}
              overlay={getContextMenuForPhoto(photo, onContextMenuClick)}
            >
              {image}
            </Dropdown>
          </Popover>
        </Badge.Ribbon>
        <div className="time-overlay">{photo.file_date_time}</div>
      </div>
    )
  }
  return (
    <div className={`img-preview-container ${selected ? 'selected' : ''}`}>
      <Popover
        content={
          <img
            src={photo.photo ? photo.photo.thumbnail_url : photo.thumbnail_url}
            alt={
              photo.photo ? photo.photo.file_date_time : photo.file_date_time
            }
            title={
              photo.photo ? photo.photo.file_date_time : photo.file_date_time
            }
            style={{ height: '80vh', width: 'auto' }}
          />
        }
        placement="left"
        mouseEnterDelay={0.8}
      >
        <Dropdown
          trigger={['contextMenu']}
          overlay={getContextMenuForPhoto(photo, onContextMenuClick)}
        >
          {image}
        </Dropdown>
      </Popover>

      <div className="time-overlay">{photo.file_date_time}</div>
    </div>
  )
}

PreviewImage.propTypes = {
  onClick: PropTypes.any,
  onContextMenuClick: PropTypes.any,
  photo: PropTypes.shape({
    file_date_time: PropTypes.any,
    photo: PropTypes.shape({
      file_date_time: PropTypes.any,
      thumbnail_url: PropTypes.any
    }),
    thumbnail_url: PropTypes.any,
    validated_by_user_id: PropTypes.any
  }),
  selected: PropTypes.any
}

export default PreviewImage
