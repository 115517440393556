import React, { Component } from 'react'
import { Row, Col, Statistic, Spin, Alert, Progress } from 'antd'
import { filter } from 'rxjs/operators'
import { getSelectedAtpEvent } from '../../utils/AtpEventManagement'
import {
  NotificationSubject,
  NotificationNames
} from '../../utils/NotificationManagement'
import {
  getImportedPhotoCountOfEvent,
  getTaggedPhotoCountOfEvent
} from '../../api/photoImport'

class ImportierteUndGetaggteFotos extends Component {
  state = {
    loading: false,
    error: null,
    photoCount: 0,
    taggedPhotosCount: 0
  }

  constructor(props) {
    super(props)
    this.unsubscribeFromNotifications = NotificationSubject.pipe(
      filter(
        (f) =>
          f.notificationName ===
          NotificationNames.userSelectedAtpEventNotification
      )
    ).subscribe(() => {
      this.loadData()
    })
  }

  loadData = async () => {
    this.setState({ loading: false, error: null })
    const event = getSelectedAtpEvent()

    if (event) {
      const { photoCount } = (await getImportedPhotoCountOfEvent(event.id)).data
      if (photoCount >= 0) {
        this.setState({ photoCount })
      } else {
        this.setState({ error: 'Fehler beim Laden der Infos.' })
      }

      const { taggedPhotosCount } = (
        await getTaggedPhotoCountOfEvent(event.id)
      ).data

      if (taggedPhotosCount >= 0) {
        this.setState({ taggedPhotosCount })
      } else {
        this.setState({ error: 'Fehler beim Laden der Infos.' })
      }

      this.setState({ loading: false })
    } else {
      this.setState({ loading: false, error: 'Kein Event ausgewählt.' })
    }
  }

  componentDidMount = () => {
    this.loadData()
  }

  componentWillUnmount = () => {
    this.unsubscribeFromNotifications.unsubscribe()
  }

  render() {
    const { loading, error, photoCount, taggedPhotosCount } = this.state
    return (
      <Spin spinning={loading}>
        {error && <Alert type="warning" showIcon message={error} />}
        {!error && (
          <Row
            gutter={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Col xs={24} md={8}>
              <Statistic title="Importierte Fotos" value={photoCount || 0} />
            </Col>
            <Col xs={24} md={8}>
              <Statistic
                title="Getaggte Fotos"
                value={taggedPhotosCount || 0}
              />
            </Col>
            <Col xs={24} md={8}>
              {photoCount > 0 ? (
                <>
                  <Progress
                    size="small"
                    type="dashboard"
                    percent={((taggedPhotosCount || 0) / photoCount) * 100}
                    format={(val) => `${Number(val).toFixed(0)} %`}
                  />
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        )}
      </Spin>
    )
  }
}

export default ImportierteUndGetaggteFotos
