import { apiGet, apiPost } from '../api'

/** GET */
export const getTaggedPhotosOfStartNo = (startNo, eventId) =>
  apiGet('photoTagging', 'getTaggedPhotosOfStartNo', { startNo, eventId })

/** POST */
export const updateTaggedPhotosOfStartNoAndCameraPosition = (
  startNo,
  cameraId,
  photoImportIds
) =>
  apiPost('photoTagging', 'updateTaggedPhotosOfStartNoAndCameraPosition', {
    startNo,
    cameraId,
    photoImportIds
  })

export const deleteTaggedPhotosOfStartNoAndCameraPosition = (
  startNo,
  cameraId,
  photoImportIds
) =>
  apiPost('photoTagging', 'deleteTaggedPhotosOfStartNoAndCameraPosition', {
    startNo,
    cameraId,
    photoImportIds
  })

export const tagPhotosOfCameraWithOffsets = (
  cameraPositionId,
  offsetMinus,
  offsetPlus,
  splitTimes,
  replaceTags,
  bewerbIds
) =>
  apiPost('photoTagging', 'tagPhotosOfCameraWithOffsets', {
    cameraPositionId,
    offsetMinus,
    offsetPlus,
    splitTimes,
    replaceTaggedPhotos: replaceTags ? 1 : 0,
    bewerbIds
  })

export const setTaggedPhotosValidatedByUser = (taggedPhotoIds) =>
  apiPost('photoTagging', 'setPhotosValidatedByUser', { taggedPhotoIds })
