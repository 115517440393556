import PropTypes from 'prop-types'
import React from 'react'
import { FormatMoney } from '../Formatter'

function EuroBetrag({ amount, size, currency, colorized }) {
  size && size === 'small' ? (
    <React.Fragment>
      <small className="text-secondary">{currency || 'EUR'}</small>{' '}
      <small
        style={{ color: colorized ? getColorForAmount(amount) : undefined }}
      >
        {FormatMoney(amount, 2)}
      </small>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <small className="text-secondary">{currency || 'EUR'}</small>{' '}
      <b style={{ color: colorized ? getColorForAmount(amount) : undefined }}>
        {FormatMoney(amount, 2)}
      </b>
    </React.Fragment>
  )
}

EuroBetrag.propTypes = {
  amount: PropTypes.any,
  colorized: PropTypes.any,
  currency: PropTypes.string,
  size: PropTypes.string
}

const getColorForAmount = (amount) => {
  if (Number(amount) > 0) return '#7bbf3b'
  if (Number(amount) < 0) return '#f55353'

  return null
}

export default EuroBetrag
