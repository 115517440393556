import React, { Component } from 'react'
import {
  Card,
  Modal,
  message,
  Typography,
  Form,
  Select,
  Alert,
  Spin,
  Divider,
  Row,
  Col,
  Input,
  Button,
  Upload,
  Checkbox
} from 'antd'
import {
  LinkOutlined,
  SaveOutlined,
  FileOutlined,
  UploadOutlined
} from '@ant-design/icons'
import { filter } from 'rxjs/operators'
import {
  NotificationSubject,
  NotificationNames
} from '../../../utils/NotificationManagement'
import { getSelectedAtpEvent } from '../../../utils/AtpEventManagement'
import { getAvailableApis } from '../../../api/timeMeasurement'
import {
  getEventConfigValueForKey,
  setEventConfigValueForKey
} from '../../../api/config'
import {
  uploadParticipantList,
  uploadSplitTimes
} from '../../../api/participant'
import PicthisTeilnehmerlisteHochladenModal from '../../Modals/PicthisTeilnehmerlisteHochladenModal'

class EinstellungenZeitmessung extends Component {
  state = {
    loading: false,
    error: null,
    apis: [],
    apiForEvent: null,
    uploadingParticipantList: false,
    participantListFromUrl: null,
    uploadingParticipantFileList: [],
    splitTimesFromUrl: null,
    uploadingSplitTimes: false,
    splitTimesUploadFileList: [],
    deleteAllBeforeUploadingParticipantFileList: false,
    showPicthisUploadModal: false
  }

  constructor(props) {
    super(props)
    this.unsubscribeFromNotifications = NotificationSubject.pipe(
      filter(
        (f) =>
          f.notificationName ===
          NotificationNames.userSelectedAtpEventNotification
      )
    ).subscribe(() => {
      this.loadAvailableZeitmessungApis()
      this.loadAllData()
    })
  }

  componentWillUnmount = () => {
    this.unsubscribeFromNotifications.unsubscribe()
  }

  loadAvailableZeitmessungApis = async () => {
    this.setState({ loading: true })
    const { success, error, apis } = (await getAvailableApis()).data

    if (success) {
      this.setState({ loading: false, apis })
    } else {
      this.setState({ loading: false, error })
    }
  }

  loadSelectedApiForEvent = async () => {
    this.setState({ loading: true })
    const event = getSelectedAtpEvent()

    if (event) {
      const { success, error, config_value: configValue } = (
        await getEventConfigValueForKey(event.id, 'TIME_MEASUREMENT_API')
      ).data

      if (success) {
        this.setState({ loading: false, apiForEvent: configValue })
      } else {
        this.setState({ loading: false, error })
      }
    } else {
      this.setState({ loading: false })
      Modal.warn({
        title: 'Achtung',
        content: 'Es wurde noch kein Event ausgewählt.'
      })
    }
  }

  updateSelectedApiForEvent = async (api) => {
    this.setState({ loading: true })

    const event = getSelectedAtpEvent()

    if (event) {
      const { success, message: m, error } = (
        await setEventConfigValueForKey(event.id, 'TIME_MEASUREMENT_API', api)
      ).data

      if (success) {
        this.setState({ loading: false, apiForEvent: api }, () => {
          message.success(m)
        })
      } else {
        this.setState({ loading: false }, () => {
          message.error(error)
        })
      }
    }
  }

  handleUploadParticipantList = async () => {
    this.setState({ uploadingParticipantList: true })

    const event = getSelectedAtpEvent()
    const {
      uploadingParticipantFileList,
      deleteAllBeforeUploadingParticipantFileList
    } = this.state

    const { success, error, errorCount } = (
      await uploadParticipantList(
        event.id,
        uploadingParticipantFileList[0],
        deleteAllBeforeUploadingParticipantFileList ? 1 : 0
      )
    ).data

    if (success) {
      this.setState({
        uploadingParticipantList: false,
        uploadingParticipantFileList: [],
        deleteAllBeforeUploadingParticipantFileList: false,
        error:
          errorCount && errorCount > 0
            ? `Bei ${errorCount} Personen ist ein Fehler aufgetreten.`
            : null
      })
      message.success('Die Teilnehmerliste wurde erfolgreich hochgeladen.')
    } else {
      this.setState({ uploadingParticipantList: false })
      message.error(error)
    }
  }

  handleUploadSplitTimes = async () => {
    this.setState({ uploadingSplitTimes: true })

    const event = getSelectedAtpEvent()
    const { splitTimesUploadFileList } = this.state

    const { success, error, errorCount } = (
      await uploadSplitTimes(event.id, splitTimesUploadFileList[0])
    ).data

    if (success) {
      this.setState({
        uploadingSplitTimes: false,
        error:
          errorCount && errorCount > 0
            ? `Bei ${errorCount} Personen ist ein Fehler aufgetreten.`
            : null
      })
      message.success('Die Split-Zeiten wurden erfolgreich hochgeladen.')
    } else {
      this.setState({ uploadingSplitTimes: false })
      message.error(error)
    }
  }

  handleShowPicthisUploadModal = () => {
    this.setState({ showPicthisUploadModal: true })
  }

  loadAllData = () => {
    this.loadAvailableZeitmessungApis()
    this.loadSelectedApiForEvent()
  }

  componentDidMount = () => {
    this.loadAllData()
  }

  render() {
    // const event = getSelectedAtpEvent();

    const {
      loading,
      error,
      apiForEvent,
      apis,
      participantListFromUrl,
      uploadingParticipantFileList,
      uploadingParticipantList,
      splitTimesFromUrl,
      uploadingSplitTimes,
      splitTimesUploadFileList,
      deleteAllBeforeUploadingParticipantFileList,
      showPicthisUploadModal
    } = this.state

    const formLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    }

    // für den Upload
    const participantListUploadProps = {
      onRemove: (file) => {
        this.setState(() => {
          const index = uploadingParticipantFileList.indexOf(file)
          const newFileList = uploadingParticipantFileList.slice()
          newFileList.splice(index, 1)
          return {
            uploadingParticipantFileList: newFileList
          }
        })
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          uploadingParticipantFileList: [
            ...state.uploadingParticipantFileList,
            file
          ]
        }))
        return false
      },
      fileList: uploadingParticipantFileList
    }

    const splitTimesUploadProps = {
      onRemove: (file) => {
        this.setState(() => {
          const index = splitTimesUploadFileList.indexOf(file)
          const newFileList = splitTimesUploadFileList.slice()
          newFileList.splice(index, 1)
          return {
            splitTimesUploadFileList: newFileList
          }
        })
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          splitTimesUploadFileList: [...state.splitTimesUploadFileList, file]
        }))
        return false
      },
      fileList: splitTimesUploadFileList
    }

    return (
      <Card title="Zeitmessung">
        <Spin spinning={loading}>
          {error && (
            <Alert
              type="error"
              showIcon
              message={error}
              style={{ marginBottom: '12px' }}
            />
          )}
          <Typography.Title level={4}>API für Zeitmessung</Typography.Title>
          <Form {...formLayout} labelAlign="left">
            <Form.Item label="API für ausgewähltes Event" required>
              <Select
                options={apis.map((api) => ({ label: api, value: api }))}
                value={apiForEvent}
                onChange={this.updateSelectedApiForEvent}
              />
            </Form.Item>
          </Form>
          <Divider type="horizontal" style={{ margin: '36px 0' }} />
          <Typography.Title level={4}>
            Teilnehmerliste importieren
          </Typography.Title>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Card
                size="small"
                title="Über URL importieren"
                extra={
                  <Button
                    size="small"
                    type="primary"
                    icon={<SaveOutlined />}
                    disabled={!participantListFromUrl}
                  >
                    importieren
                  </Button>
                }
              >
                <Input
                  size="small"
                  placeholder="https://...."
                  addonBefore={<LinkOutlined />}
                  value={participantListFromUrl}
                  onChange={(e) => {
                    this.setState({ participantListFromUrl: e.target.value })
                  }}
                />
              </Card>
            </Col>
            <Col xs={24} lg={12}>
              <Card
                size="small"
                title="Datei hochladen"
                extra={
                  <>
                    <Checkbox
                      checked={deleteAllBeforeUploadingParticipantFileList}
                      onChange={(e) => {
                        this.setState({
                          deleteAllBeforeUploadingParticipantFileList:
                            e.target.checked
                        })
                      }}
                    >
                      Teilnehmerliste vorher leeren?
                    </Checkbox>

                    <Button
                      size="small"
                      type="primary"
                      icon={<SaveOutlined />}
                      loading={uploadingParticipantList}
                      onClick={this.handleUploadParticipantList}
                      disabled={
                        !uploadingParticipantFileList ||
                        uploadingParticipantFileList.length === 0
                      }
                      style={{ marginLeft: '6px' }}
                    >
                      importieren
                    </Button>
                  </>
                }
              >
                <Upload showUploadList {...participantListUploadProps}>
                  <Button size="small" disabled={uploadingParticipantList}>
                    <FileOutlined /> Datei auswählen
                  </Button>
                </Upload>
              </Card>
            </Col>
          </Row>
          <Divider type="horizontal" style={{ margin: '36px 0' }} />
          <Typography.Title level={4}>
            Aktuelle Split-Zeiten importieren
          </Typography.Title>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Card
                size="small"
                title="Über URL importieren"
                extra={
                  <Button
                    size="small"
                    type="primary"
                    icon={<SaveOutlined />}
                    disabled={!splitTimesFromUrl}
                  >
                    importieren
                  </Button>
                }
              >
                <Input
                  size="small"
                  placeholder="https://...."
                  addonBefore={<LinkOutlined />}
                  value={splitTimesFromUrl}
                  onChange={(e) => {
                    this.setState({ splitTimesFromUrl: e.target.value })
                  }}
                />
              </Card>
            </Col>
            <Col xs={24} lg={12}>
              <Card
                size="small"
                title="Datei hochladen"
                extra={
                  <Button
                    size="small"
                    type="primary"
                    icon={<SaveOutlined />}
                    loading={uploadingSplitTimes}
                    onClick={this.handleUploadSplitTimes}
                    disabled={
                      !splitTimesUploadFileList ||
                      splitTimesUploadFileList.length === 0
                    }
                  >
                    importieren
                  </Button>
                }
              >
                <Upload showUploadList {...splitTimesUploadProps}>
                  <Button size="small" disabled={uploadingSplitTimes}>
                    <FileOutlined /> Datei auswählen
                  </Button>
                </Upload>
              </Card>
            </Col>
          </Row>
          <Divider type="horizontal" style={{ margin: '36px 0' }} />
          <Row gutter={24}>
            <Col xs={24}>
              <Button
                type="primary"
                icon={<UploadOutlined />}
                onClick={this.handleShowPicthisUploadModal}
              >
                Teilnehmer bei picthis hochladen
              </Button>
            </Col>
          </Row>
        </Spin>
        <PicthisTeilnehmerlisteHochladenModal
          visible={showPicthisUploadModal}
          onCancel={() => {
            this.setState({ showPicthisUploadModal: false })
          }}
          onSuccess={() => {
            this.setState({ showPicthisUploadModal: false })
          }}
        />
      </Card>
    )
  }
}

export default EinstellungenZeitmessung
