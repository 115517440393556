import React from 'react'
import { Menu } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  DashboardOutlined,
  SettingFilled,
  TagOutlined,
  UserSwitchOutlined,
  FieldTimeOutlined,
  UserOutlined
} from '@ant-design/icons'
import UserManagement from '../../utils/UserManagement'

function Navigation() {
  let { pathname } = useLocation()

  // den ersten "pfad" ausschneiden
  pathname = pathname.substring(
    0,
    pathname.indexOf('/', 1) > 0 ? pathname.indexOf('/', 1) : undefined
  )

  const navigate = useNavigate()

  return (
    <Menu
      mode="horizontal"
      defaultSelectedKeys={[pathname]}
      onClick={({ key }) => {
        navigate(key)
      }}
      theme="dark"
    >
      {UserManagement.isAdmin() && (
        <Menu.Item key="/" icon={<DashboardOutlined />}>
          Dashboard
        </Menu.Item>
      )}
      {/* <Menu.Item key="/import-cam-photos" icon={<CameraFilled />}>
        Import
      </Menu.Item>
      <Menu.Item key="/ftp-import" icon={<HddOutlined />}>
        FTP-Import
      </Menu.Item> */}
      <Menu.Item key="/photo-tagging" icon={<TagOutlined />}>
        Tagging
      </Menu.Item>
      {UserManagement.isAdmin() && (
        <Menu.Item key="/participants" icon={<UserSwitchOutlined />}>
          Teilnehmer
        </Menu.Item>
      )}
      {UserManagement.isAdmin() && (
        <Menu.Item key="/settings" icon={<SettingFilled />}>
          Einstellungen
        </Menu.Item>
      )}
      {UserManagement.isAdmin() && (
        <Menu.Item key="/events" icon={<FieldTimeOutlined />}>
          Events
        </Menu.Item>
      )}
      {UserManagement.isAdmin() && (
        <Menu.Item key="/users" icon={<UserOutlined />}>
          Benutzer
        </Menu.Item>
      )}
    </Menu>
  )
}

export default Navigation
