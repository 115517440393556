import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Typography, Row, Col } from 'antd'
import { Route, Routes } from 'react-router-dom'
import EinstellungenMenu from './EinstellungenMenu'
import EinstellungenAllgemein from './EinstellungenMenus/EinstellungenAllgemein'
import EinstellungenZeitmessung from './EinstellungenMenus/EinstellungenZeitmessung'
import EinstellungenFotoImport from './EinstellungenMenus/EinstellungenFotoImport'
import EinstellungenKameraPositionen from './EinstellungenMenus/EinstellungenKameraPositionen'
import { withRouter } from '../../withRouter'

class Einstellungen extends Component {
  state = { activeMenuKey: null }

  handleMenuClick = ({ key }) => {
    this.setState({ activeMenuKey: key }, () => {
      const { navigate } = this.props
      navigate(`/settings/${key}`)
    })
  }

  componentDidMount = () => {
    const key = this.props
    if (key) this.setState({ activeMenuKey: key })
  }

  render() {
    const { activeMenuKey } = this.state

    return (
      <>
        <Typography.Title level={1}>Einstellungen</Typography.Title>
        <Row gutter={24}>
          <Col xs={24} xl={6} xxl={4}>
            <EinstellungenMenu
              activeKey={activeMenuKey}
              onMenuClick={this.handleMenuClick}
              width="100%"
            />
          </Col>
          <Col xs={24} xl={18} xxl={20}>
            <Routes>
              <Route element={<EinstellungenAllgemein />} path="/" />
              <Route element={<EinstellungenAllgemein />} path="/allgemein/*" />
              <Route
                element={<EinstellungenZeitmessung />}
                path="/zeitmessung/*"
              />
              <Route
                element={<EinstellungenFotoImport />}
                path="/foto-import/*"
              />
              <Route
                element={<EinstellungenKameraPositionen />}
                path="/kamera-positionen/*"
              />
            </Routes>
          </Col>
        </Row>
      </>
    )
  }
}

Einstellungen.propTypes = {
  navigate: PropTypes.func
}

export default withRouter(Einstellungen)
