import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert, DatePicker, Form, Input, Modal, Switch } from 'antd'
import { createSubEvent } from '../../api/event'

class NeuesSubEventModal extends Component {
  state = {
    loading: false,
    error: null,
    eventName: null,
    eventStartDate: null,
    eventEndDate: null,
    urlKeywords: null,
    showOnWebsite: false
  }

  resetState = () => {
    this.setState({
      loading: false,
      error: null,
      eventName: null,
      eventStartDate: null,
      eventEndDate: null,
      urlKeywords: null,
      showOnWebsite: false
    })
  }

  handleSave = async () => {
    this.setState({ loading: true })
    const { event, onSuccess } = this.props
    const {
      eventName,
      eventStartDate,
      eventEndDate,
      urlKeywords,
      showOnWebsite
    } = this.state

    const {
      success,
      error,
      message: m
    } = (
      await createSubEvent(
        event.id,
        eventName,
        eventStartDate.format('YYYY-MM-DD HH:mm:ss'),
        eventEndDate.format('YYYY-MM-DD HH:mm:ss'),
        urlKeywords,
        showOnWebsite
      )
    ).data

    if (success) {
      this.setState({ loading: false }, () => {
        this.resetState()
        onSuccess(m)
      })
    } else {
      this.setState({ loading: false, error })
    }
  }

  render() {
    const {
      loading,
      error,
      eventName,
      eventStartDate,
      eventEndDate,
      urlKeywords,
      showOnWebsite
    } = this.state
    const { visible, onClose, event } = this.props

    return (
      <Modal
        visible={visible}
        onCancel={onClose}
        title="Sub-Event hinzufügen"
        okText="Sub-Event hinzufügen"
        confirmLoading={loading}
        onOk={this.handleSave}
      >
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '24px' }}
          />
        )}
        {event && (
          <>
            <Form wrapperCol={{ span: 15 }} labelCol={{ span: 9 }}>
              <Form.Item label="Event Name" required>
                <Input
                  placeholder="Sub-Event Name"
                  onChange={(e) => {
                    this.setState({ eventName: e.target.value })
                  }}
                  value={eventName}
                />
              </Form.Item>
              <Form.Item label="Start.Datum" required>
                <DatePicker
                  value={eventStartDate}
                  onChange={(date) => {
                    this.setState({ eventStartDate: date })
                  }}
                  format="ddd, DD.MM.YYYY"
                />
              </Form.Item>
              <Form.Item label="End-Datum" required>
                <DatePicker
                  value={eventEndDate}
                  onChange={(date) => {
                    this.setState({ eventEndDate: date })
                  }}
                  format="ddd, DD.MM.YYYY"
                />
              </Form.Item>
              <Form.Item
                label="URL Keywords"
                required
                help={`picthis.one/${event.url_keywords}/${urlKeywords || ''}`}
              >
                <Input
                  placeholder="Steht in der URL"
                  onChange={(e) => {
                    this.setState({ urlKeywords: e.target.value })
                  }}
                  value={urlKeywords}
                />
              </Form.Item>
              <Form.Item label="Auf Webseite anzeigen?">
                <Switch
                  checked={showOnWebsite}
                  onChange={(checked) => {
                    this.setState({ showOnWebsite: checked })
                  }}
                />
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    )
  }
}

NeuesSubEventModal.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.any,
    url_keywords: PropTypes.any
  }),
  onClose: PropTypes.any,
  onSuccess: PropTypes.func,
  visible: PropTypes.any
}

export default NeuesSubEventModal
