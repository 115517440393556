import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import EinzelnerTeilnehmer from './EinzelnerTeilnehmer'
import TeilnehmerListe from './TeilnehmerListe'

class Teilnehmer extends Component {
  state = {}

  render() {
    return (
      <>
        <Routes>
          <Route element={<TeilnehmerListe />} path="/" />
          <Route element={<EinzelnerTeilnehmer />} path=":id" />
        </Routes>
      </>
    )
  }
}

export default Teilnehmer
