import React, { Component } from 'react'
import { Alert, Table } from 'antd'
import moment from 'moment'
import {
  CheckCircleFilled,
  HourglassOutlined,
  StopOutlined
} from '@ant-design/icons'
import { Eps, Mastercard, Visa } from 'react-pay-icons'
import { getCharges } from '../../api/stripe'
import EuroBetrag from '../../utils/ui/EuroBetrag'

class LetzteStripeZahlungenWidget extends Component {
  state = { loading: false, error: null, charges: [] }

  loadCharges = async () => {
    this.setState({ loading: true })
    const { success, error, charges } = (await getCharges(5)).data

    if (success) {
      this.setState({ loading: false, charges })
    } else {
      this.setState({ loading: false, error })
    }
  }

  componentDidMount = () => {
    this.loadCharges()
  }

  render() {
    const { loading, error, charges } = this.state

    return (
      <>
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '12px' }}
          />
        )}
        <Table
          rowKey="id"
          size="small"
          loading={loading}
          dataSource={(charges && charges.data) || []}
          columns={[
            {
              title: 'Datum',
              key: 'date',
              render: (charge) =>
                moment.unix(Number(charge.created)).format('LL, HH:mm')
            },
            {
              title: 'Betrag (EUR)',
              key: 'amount',
              render: (charge) => (
                <EuroBetrag
                  currency={charge.currency.toLocaleUpperCase()}
                  amount={charge.amount}
                />
              ),
              align: 'right'
            },
            {
              title: 'E-Mail',
              key: 'email',
              render: (charge) => <code>{charge.billing_details.email}</code>
            },
            {
              title: 'Zahlungsmethode',
              key: 'payment_method',
              render: (charge) => {
                if (charge.payment_method_details) {
                  switch (charge.payment_method_details.type) {
                    case 'eps':
                      return <Eps style={{ height: '18px' }} />
                    case 'card':
                      if (
                        charge.payment_method_details.card.brand ===
                        'mastercard'
                      ) {
                        return <Mastercard style={{ height: '18px' }} />
                      }

                      if (charge.payment_method_details.card.brand === 'visa') {
                        return <Visa style={{ height: '18px' }} />
                      }

                      return charge.payment_method_details.card.brand
                    default:
                      return charge.payment_method_details.type
                  }
                } else {
                  return <small>-</small>
                }
              }
            },
            {
              title: 'Status',
              key: 'status',
              render: (charge) => {
                switch (charge.status) {
                  case 'succeeded':
                    return <CheckCircleFilled style={{ color: '#72f542' }} />
                  case 'pendig':
                    return <HourglassOutlined style={{ color: '#aaaaaa' }} />
                  case 'failed':
                    return <StopOutlined style={{ color: 'red' }} />
                  default:
                    return charge.status
                }
              },
              width: 80,
              align: 'center'
            }
          ]}
          pagination={false}
        />
      </>
    )
  }
}

export default LetzteStripeZahlungenWidget
