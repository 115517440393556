import { apiGet, apiPost } from '../api'

/** GET */
export const getConfigValueForKey = (configKey) =>
  apiGet('config', 'configValueForKey', { configKey })
export const getEventConfigValueForKey = (eventId, configKey) =>
  apiGet('eventConfig', 'configValueForKey', { eventId, configKey })

/** POST */
export const setConfigValueForKey = (configKey, configValue) =>
  apiPost('config', 'setConfigValueForKey', { configKey, configValue })

export const setEventConfigValueForKey = (eventId, configKey, configValue) =>
  apiPost('eventConfig', 'setConfigValueForKey', {
    eventId,
    configKey,
    configValue
  })
