import {
  NotificationSubject,
  NotificationNames
} from './NotificationManagement'

let selectedEvent = null

export const setSelectedAtpEvent = (event) => {
  selectedEvent = event

  NotificationSubject.next({
    notificationName: NotificationNames.userSelectedAtpEventNotification,
    data: { event }
  })
}
export const getSelectedAtpEvent = () => selectedEvent
