import { EditOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Alert, Button, message, PageHeader, Switch, Table } from 'antd'
import React, { Component } from 'react'
import { getAllUsers, toggleActive, toggleAdmin } from '../../api/user'
import EditUserModal from './EditUserModal'
import NeuerUserModal from './NeuerUserModal'

class Users extends Component {
  state = {
    loading: false,
    error: null,
    users: [],
    selectedUser: null,
    showCreateNewUserModal: false
  }

  loadUsers = async (callback) => {
    this.setState({ loading: true, error: null })
    const { success, error, users } = (await getAllUsers()).data

    if (success) {
      this.setState({ loading: false, users }, callback)
    } else {
      this.setState({ loading: false, error })
    }
  }

  toggleUserActive = async (userId, active) => {
    this.setState({ loading: true, error: null })
    const {
      success,
      error,
      message: m
    } = (await toggleActive(userId, active)).data

    if (success) {
      this.setState({ loading: false }, this.loadUsers)
      message.success(m)
    } else {
      this.setState({ loading: false, error })
    }
  }

  toggleUserAdmin = async (userId, isAdmin) => {
    this.setState({ loading: true, error: null })
    const {
      success,
      error,
      message: m
    } = (await toggleAdmin(userId, isAdmin)).data

    if (success) {
      this.setState({ loading: false }, this.loadUsers)
      message.success(m)
    } else {
      this.setState({ loading: false, error })
    }
  }

  componentDidMount = () => {
    this.loadUsers()
  }

  render() {
    const { loading, error, users, selectedUser, showCreateNewUserModal } =
      this.state
    return (
      <>
        <PageHeader
          title="Benutzer"
          extra={
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => {
                this.setState({ showCreateNewUserModal: true })
              }}
            >
              Neuer Benutzer
            </Button>
          }
        >
          {error && (
            <Alert
              type="error"
              showIcon
              message={error}
              style={{ marginBottom: '24px' }}
            />
          )}
          <Table
            rowKey="id"
            bordered
            pagination={false}
            dataSource={users}
            columns={[
              { title: 'Benutzername', dataIndex: 'alias', width: 120 },
              {
                title: 'Name',
                key: 'name',
                render: (user) => `${user.first_name} ${user.last_name}`,
                width: 300
              },
              { title: 'E-Mail', dataIndex: 'email', width: 300 },
              {
                title: 'Admin?',
                key: 'admin',
                render: (user) => (
                  <Switch
                    checked={Number(user.is_admin) === 1}
                    loading={loading}
                    onChange={(checked) => {
                      this.toggleUserAdmin(user.id, checked)
                    }}
                    size="small"
                  />
                ),
                width: 140
              },
              {
                title: 'Aktiv?',
                key: 'active',
                render: (user) => (
                  <Switch
                    loading={loading}
                    checked={Number(user.active) === 1}
                    onChange={(checked) => {
                      this.toggleUserActive(user.id, checked)
                    }}
                    size="small"
                  />
                ),
                width: 140
              },
              {
                title: '',
                key: 'options',
                render: (user) => (
                  <Button
                    icon={<EditOutlined />}
                    type="link"
                    onClick={() => {
                      this.setState({ selectedUser: user })
                    }}
                  >
                    bearbeiten
                  </Button>
                )
              }
            ]}
          />
        </PageHeader>
        <NeuerUserModal
          visible={showCreateNewUserModal}
          onClose={() => {
            this.setState({ showCreateNewUserModal: false })
          }}
          onSuccess={(msg, userId) => {
            message.success(msg)
            this.loadUsers(() => {
              const { users } = this.state
              this.setState({
                showCreateNewUserModal: false,
                selectedUser: users.find((u) => u.id === userId)
              })
            })
          }}
        />
        <EditUserModal
          visible={selectedUser != null}
          user={selectedUser}
          onClose={() => {
            this.setState({ selectedUser: null })
          }}
        />
      </>
    )
  }
}

export default Users
