import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Modal, Alert, Form, InputNumber, Input, message } from 'antd'
import { getSelectedAtpEvent } from '../../../../utils/AtpEventManagement'
import { addCameraPositionForEvent } from '../../../../api/cameraPosition'

class KameraPositionHinzufuegenModal extends Component {
  state = { loading: false, error: null, description: null, number: null }

  trySaveCameraPosition = async () => {
    const event = getSelectedAtpEvent()

    if (event) {
      this.setState({ loading: true })
      const { description, number } = this.state
      const { onSuccess } = this.props

      if (description && number) {
        const {
          success,
          error,
          message: m
        } = (await addCameraPositionForEvent(event.id, number, description))
          .data

        if (success) {
          this.setState({ loading: false }, () => {
            onSuccess(m)
          })
        } else {
          this.setState({ loading: false, error })
        }
      } else {
        this.setState({
          loading: false,
          error: 'Es müssen alle Felder ausgefüllt werden.'
        })
      }
    } else {
      message.error('Es wurde kein Event ausgewählt.')
    }
  }

  render() {
    const { loading, error, description, number } = this.state
    const { visible, onCancel } = this.props

    return (
      <Modal
        visible={visible}
        width={600}
        title="Kamera-Position hinzufügen"
        onCancel={onCancel}
        closable
        okText="Speichern"
        onOk={this.trySaveCameraPosition}
        cancelText="Abbrechen"
        confirmLoading={loading}
      >
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '12px' }}
          />
        )}
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Form.Item label="Nummer">
            <InputNumber
              min={1}
              value={number}
              onChange={(value) => {
                this.setState({ number: value })
              }}
            />
          </Form.Item>
          <Form.Item label="Beschreibung">
            <Input
              value={description}
              onChange={(e) => {
                this.setState({ description: e.target.value })
              }}
              placeholder="z.B. der Standort, wo die Kamera platziert ist"
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

KameraPositionHinzufuegenModal.propTypes = {
  onCancel: PropTypes.any,
  onSuccess: PropTypes.func,
  visible: PropTypes.any
}

export default KameraPositionHinzufuegenModal
