import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert, Button, Col, Row, Spin } from 'antd'
import moment from 'moment'
import {
  getEventForCameraPosition,
  getPhotosPaginated
} from '../../api/cameraPosition'
import { withRouter } from '../../withRouter'

class FotosDurchsehen extends Component {
  state = {
    loading: false,
    error: null,
    images: [],
    participantList: [],
    selectedParticipant: null,
    selectedTime: null,
    selectedSplitTimes: [],
    currentPage: 1,
    event: null,
    offsetMinus: 10,
    offsetPlus: 10
  }

  loadEvent = async () => {
    const { params } = this.props
    const { cameraId } = params

    if (cameraId) {
      this.setState({ loading: true })
      const { success, error, event } = (
        await getEventForCameraPosition(cameraId)
      ).data
      if (success) {
        this.setState({ loading: false, event }, () => {
          this.loadImagesPaginated()
        })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  loadImagesPaginated = async () => {
    const { params } = this.props
    const { cameraId } = params

    if (cameraId) {
      this.setState({ loading: true })
      const { currentPage } = this.state

      const { success, error, photos } = (
        await getPhotosPaginated(cameraId, (currentPage - 1) * 25, 25)
      ).data

      if (success) {
        this.setState({ loading: false, images: photos })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  componentDidMount = () => {
    this.loadEvent()
  }

  render() {
    const { loading, error, images, currentPage } = this.state

    return (
      <>
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '24px' }}
          />
        )}
        <Spin spinning={loading}>
          <Row gutter={48}>
            <Col lg={24}>
              {images.length === 0 && (
                <Alert type="info" showIcon message="Keine Fotos gefunden." />
              )}
              {(images.length > 0 || currentPage > 1) && (
                <>
                  <Button
                    type="default"
                    disabled={currentPage === 1}
                    onClick={() => {
                      this.setState(
                        { currentPage: currentPage - 1 },
                        this.loadImagesPaginated
                      )
                    }}
                  >
                    zurück
                  </Button>{' '}
                  <Button
                    type="default"
                    onClick={() => {
                      this.setState(
                        { currentPage: currentPage + 1 },
                        this.loadImagesPaginated
                      )
                    }}
                    disabled={images.length === 0}
                  >
                    weiter
                  </Button>
                </>
              )}
              {images.length > 0 && (
                <Row gutter={[24, 24]}>
                  {images.map((i) => (
                    <Col md={12} xl={8} key={i.id}>
                      <div style={{ padding: '24px', textAlign: 'center' }}>
                        <a
                          href={i.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            key={i.id}
                            src={i.thumbnail_url}
                            title={i.new_filename}
                            alt={i.new_filename}
                            style={{
                              width: '100%',
                              height: 'auto',
                              objectFit: 'cover',
                              maxHeight: '240px'
                            }}
                          />
                        </a>
                        {moment(i.file_date_time).format('HH:mm:ss')}
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
              {(images.length > 0 || currentPage > 1) && (
                <>
                  <Button
                    type="default"
                    disabled={currentPage === 1}
                    onClick={() => {
                      this.setState(
                        { currentPage: currentPage - 1 },
                        this.loadImagesPaginated
                      )
                    }}
                  >
                    zurück
                  </Button>{' '}
                  <Button
                    type="default"
                    onClick={() => {
                      this.setState(
                        { currentPage: currentPage + 1 },
                        this.loadImagesPaginated
                      )
                    }}
                    disabled={images.length === 0}
                  >
                    weiter
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Spin>
      </>
    )
  }
}

FotosDurchsehen.propTypes = {
  params: PropTypes.any
}

export default withRouter(FotosDurchsehen)
