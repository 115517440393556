import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { PlusCircleFilled } from '@ant-design/icons'
import { Alert, Button, Drawer, message } from 'antd'
import SubeventBewerbCard from './SubeventBewerbCard'
import { getSubeventBewerbe } from '../../api/event'

class SubeventBewerbeDrawer extends Component {
  state = { loading: false, error: null, bewerbe: [], createNewBewerb: false }

  loadBewerbe = async () => {
    const { subevent } = this.props

    if (subevent) {
      this.setState({ loading: true, error: null })
      const { success, error, bewerbe } = (
        await getSubeventBewerbe(subevent.id)
      ).data

      if (success) {
        this.setState({ loading: false, bewerbe })
      } else {
        this.setState({ loading: false, error })
      }
    }
  }

  handleDeleteBewerb = async () => {}

  componentDidMount = () => {
    this.loadBewerbe()
  }

  componentDidUpdate = (prevProps) => {
    const { subevent } = this.props
    const { subevent: prevSubevent } = prevProps

    if (subevent && subevent !== prevSubevent) {
      this.loadBewerbe()
    }
  }

  render() {
    const { loading, error, bewerbe, createNewBewerb } = this.state
    const { visible, onClose, subevent } = this.props
    return (
      <Drawer
        visible={visible}
        placement="right"
        width={600}
        onClose={onClose}
        title={
          subevent
            ? `Bewerbe von ${subevent.event_name} bearbeiten`
            : 'Bewerbe bearbeiten'
        }
        maskClosable={false}
        extra={
          <>
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => {
                this.setState({ createNewBewerb: true })
              }}
              loading={loading}
            >
              Bewerb hinzufügen
            </Button>
          </>
        }
      >
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '24px' }}
          />
        )}
        {createNewBewerb && (
          <SubeventBewerbCard
            subevent={subevent}
            mode="create"
            onSuccess={() => {
              this.setState({ createNewBewerb: false }, this.loadBewerbe)
            }}
          />
        )}
        {subevent && (
          <>
            {bewerbe.map((b) => (
              <SubeventBewerbCard
                key={b.id}
                bewerb={b}
                bewerbe={bewerbe}
                mode="edit"
                subevent={subevent}
                onSuccess={(msg) => {
                  this.loadBewerbe()
                  message.success(msg)
                }}
              />
            ))}
          </>
        )}
      </Drawer>
    )
  }
}

SubeventBewerbeDrawer.propTypes = {
  onClose: PropTypes.any,
  subevent: PropTypes.shape({
    event_name: PropTypes.any,
    id: PropTypes.any
  }),
  visible: PropTypes.any
}

export default SubeventBewerbeDrawer
