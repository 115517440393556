import axios from 'axios'
import qs from 'qs'

export const picthisApiPost = (object, endpoint, params) =>
  axios.post(
    '/api/picthis/api.php',
    qs.stringify({ object, endpoint, ...params })
  )

export default { picthisApiPost }
