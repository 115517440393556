import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'
import { filter } from 'rxjs/operators'
import { Typography, Alert, Card, Button, Table, Row, Input } from 'antd'
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons'
import {
  NotificationSubject,
  NotificationNames
} from '../../utils/NotificationManagement'
import { getSelectedAtpEvent } from '../../utils/AtpEventManagement'
import { getParticipantList } from '../../api/participant'
import { withRouter } from '../../withRouter'

class TeilnehmerListe extends Component {
  state = {
    loading: false,
    error: null,
    participantList: null,
    currentPage: 1,
    currentPageSize: 30,
    totalParticipants: 0,
    searchForNameOrNumber: null,
    filteredParticipantList: null
  }

  participantColumns = [
    {
      title: 'Name',
      key: 'name',
      render: (row) => (
        <Button
          size="small"
          type="link"
          style={{ margin: 0, padding: 0 }}
          onClick={() => {
            const { navigate } = this.props
            navigate(`/photo-tagging/${row.start_no}`)
          }}
        >
          {row.first_name} {row.last_name}
        </Button>
      )
    },
    {
      title: '# Start',
      key: 'start_no',
      render: (row) => row.start_no,
      width: 80,
      align: 'right'
    },
    {
      title: 'Nationalität',
      dataIndex: 'nationality',
      width: 120
    },
    {
      title: 'Geburtsdatum',
      key: 'birthdate',
      render: (row) => (row.birthday ? moment(row.birthday).format('L') : '-'),
      width: 140,
      align: 'right'
    }
  ]

  constructor(props) {
    super(props)
    this.unsubscribeFromNotifications = NotificationSubject.pipe(
      filter(
        (f) =>
          f.notificationName ===
          NotificationNames.userSelectedAtpEventNotification
      )
    ).subscribe(() => {
      this.loadParticipantList()
    })
  }

  loadParticipantList = async () => {
    this.setState({ loading: true })
    const { currentPage, currentPageSize } = this.state
    const event = getSelectedAtpEvent()

    if (event) {
      const { success, error, participantList, totalCount } = (
        await getParticipantList(
          event.id,
          (currentPage - 1) * currentPageSize,
          currentPageSize
        )
      ).data

      if (success) {
        this.setState({
          loading: false,
          error: null,
          participantList,
          totalParticipants: totalCount
        })
      } else {
        this.setState({
          loading: false,
          error,
          participantList: [],
          totalParticipants: 0
        })
      }
    } else {
      this.setState({ loading: false })
    }
  }

  handleSearch = (searchVal) => {
    if (searchVal) {
      this.setState((state) => ({
        searchForNameOrNumber: searchVal,
        filteredParticipantList: state.participantList.filter(
          (p) =>
            (p.first_name &&
              p.first_name
                .toLocaleLowerCase()
                .indexOf(searchVal.toLocaleLowerCase()) >= 0) ||
            (p.last_name &&
              p.last_name
                .toLocaleLowerCase()
                .indexOf(searchVal.toLocaleLowerCase()) >= 0) ||
            (p.start_no &&
              p.start_no
                .toLocaleLowerCase()
                .indexOf(searchVal.toLocaleLowerCase()) >= 0) ||
            (p.email &&
              p.email
                .toLocaleLowerCase()
                .indexOf(searchVal.toLocaleLowerCase()) >= 0)
        )
      }))
    } else {
      this.setState({ filteredParticipantList: null })
    }
  }

  componentDidMount = () => {
    this.loadParticipantList()
  }

  render() {
    const {
      loading,
      error,
      participantList,
      currentPage,
      currentPageSize,
      totalParticipants,
      searchForNameOrNumber,
      filteredParticipantList
    } = this.state

    const event = getSelectedAtpEvent()

    return (
      <>
        <Typography.Title level={1}>Teilnehmer-Liste</Typography.Title>
        {!event && (
          <Alert type="warning" message="Du hast noch kein Event ausgewählt." />
        )}
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ marginBottom: '12px' }}
          />
        )}
        {event && (
          <Row style={{ margin: '24px 0px' }}>
            <Input
              size="large"
              addonBefore={<SearchOutlined />}
              value={searchForNameOrNumber}
              onChange={(e) => {
                this.handleSearch(e.target.value)
              }}
              placeholder="Name oder Nummer suchen ..."
              allowClear
            />
          </Row>
        )}
        {event && (
          <Card
            size="small"
            title={`Teilnehmerliste für ${event.event_name}`}
            extra={
              <Button
                size="small"
                onClick={this.loadParticipantList}
                icon={<ReloadOutlined />}
                shape="circle"
              />
            }
            bodyStyle={{ padding: '0 0 30px 0', margin: 0 }}
          >
            <Table
              dataSource={filteredParticipantList || participantList}
              columns={this.participantColumns}
              size="small"
              loading={loading}
              pagination={{
                current: currentPage,
                pageSizeOptions: ['30', '50', '100', '200'],
                pageSize: currentPageSize,
                total:
                  (filteredParticipantList && filteredParticipantList.length) ||
                  totalParticipants,
                onShowSizeChange: (x, pageSize) => {
                  this.setState(
                    { currentPageSize: pageSize },
                    this.loadParticipantList
                  )
                },
                onChange: (page) => {
                  this.setState({ currentPage: page }, this.loadParticipantList)
                }
              }}
              rowKey="id"
            />
          </Card>
        )}
      </>
    )
  }
}

TeilnehmerListe.propTypes = {
  navigate: PropTypes.func
}

export default withRouter(TeilnehmerListe)
